import React from "react";

import ReportList from "components/Reports/ReportsList";

const Reports = () => {
  return (
    <>
      <ReportList></ReportList>
    </>
  );
};

export default Reports;
