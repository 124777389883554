import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

// import Pagination from "../Pagination/Pagination";
import NewTestCategoryForm from "components/Labs/Modals/NewTestCategory";

import {
  fetchCategoryList,
  updateStatus,
  deleteCategory,
  createNewTestCategory,
} from "store/slices/testCategories";
import SearchFilter from "components/SearchFilter/SearchFilter";

const TestCategoryList = (props) => {
  const [newTestCategoryModalOpen, setNewTestCategoryModalOpen] =
    useState(false);
  const data = useSelector((state) => state.testCategories.categoriesList);
  const loading = useSelector((state) => state.testCategories.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoryList());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.test_categories);
    setUpdatedList(data.test_categories);
  }, [data.test_categories]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const getPage = (index) => {
    dispatch(
      fetchCategoryList({
        page: index,
      })
    );
  };

  const updateCategoryStatus = (categoryId, status) => {
    dispatch(
      updateStatus({
        categoryId,
        status: status,
      })
    );
  };

  const deleteTestCategory = (categoryId) => {
    dispatch(deleteCategory(categoryId));
  };
  const toggleNewTestCategoryModal = () => {
    setNewTestCategoryModalOpen(!newTestCategoryModalOpen);
  };
  const createTestCategory = (details) => {
    dispatch(createNewTestCategory(details));
    toggleNewTestCategoryModal();
  };

  const handleChange = async (e) => {
    setSearchTerm(e.target.value);

    setUpdatedList(
      filteredList.filter((category) => {
        console.log(category);
        const description = category.description.toLowerCase();
        const name = category.name.toLowerCase();
        const status = category.status.toLowerCase();
        const search = e.target.value.toLowerCase();
        return (
          description.includes(search) ||
          name.includes(search) ||
          status.includes(search)
        );
      })
    );
  };
  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col">
                  <h1 className="mb-0" style={{ color: "#ea526f" }}>
                    {updatedList.length}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      TEST CATEGORIES
                    </span>
                  </h1>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <div className="col">
                  <Button
                    color="default"
                    type="button"
                    className="float-right"
                    onClick={() => setNewTestCategoryModalOpen(true)}
                  >
                    + ADD CATEGORY
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewTestCategoryModal()}
                    isOpen={newTestCategoryModalOpen}
                  >
                    <NewTestCategoryForm
                      handleSubmit={createTestCategory}
                      toggleNewTestCategoryModal={toggleNewTestCategoryModal}
                    />
                  </Modal>
                </div>
              </Row>
            </CardHeader>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DESCRIPTION
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((category) => {
                    return (
                      <tr key={category._id}>
                        <td>
                          <div
                            style={{
                              fontSize: "0.90rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{category.name}</strong>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            {category.description}
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(category.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {category.status || "Active"}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {category.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateCategoryStatus(category._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {category.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateCategoryStatus(
                                      category._id,
                                      "deactivated"
                                    )
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() => deleteTestCategory(category._id)}
                              >
                                Delete
                              </DropdownItem>

                              {/* <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {/* <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default TestCategoryList;
