import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TextArea,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { fetchCurrenciesList } from "store/slices/currency";

const NewCountryForm = ({ handleSubmit, toggleNewCountryModal }) => {
  const [countryDetails, setCountryDetails] = useState({
    country_name: "",
    country_code: "",
    country_flag: "",
    currency: "",
  });

  const { currencies } = useSelector(
    (state) => state.currencies.currenciesList
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCountryDetails({ ...countryDetails, [name]: value });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrenciesList());
  }, [dispatch]);

  const submitForm = () => {
    handleSubmit(countryDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewCountryModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New Country</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Country Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="country_name"
                  name="country_name"
                  placeholder="Country Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Country Code</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="country_code"
                  name="country_code"
                  placeholder="Country Code"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Country Flag</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="country_flag"
                  name="country_flag"
                  placeholder="Country Flag"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="currency">
                  <strong>Currency</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="currency"
                  name="currency"
                  type="select"
                  className="form-control-alternative"
                >
                  <option value="" disabled selected>
                    Select Currency
                  </option>
                  {currencies &&
                    currencies.map((currency) => (
                      <option value={currency._id} key={currency._id}>
                        {currency.currency_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewCountryModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};
export default NewCountryForm;
