import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row,
  Container,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Col,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";
import { deletePharmacy } from "../../services/api/pharmacies";

import NewProductForm from "components/Pharmacies/modals/NewProductForm";
import Pagination from "components/Pagination/Pagination";

import {
  fetchPharmacyProducts,
  createProduct,
  createCSVProduct,
} from "store/slices/pharmacy";
import UploadCSVForm from "./modals/UploadCSVForm";
import SearchFilter from "components/SearchFilter/SearchFilter";

const PharmacyList = (props) => {
  const [newProductModalOpen, setNewProductModalOpen] = useState(false);
  const [newCSVModalOpen, setNewCSVModalOpen] = useState(false);
  const data = useSelector((state) => state.pharmacies.pharmacyProductsList);
  const loading = useSelector((state) => state.pharmacies.loading);
  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredList(data.pharmacy_products);
    setUpdatedList(data.pharmacy_products);
  }, [data.pharmacy_products, searchTerm]);

  useEffect(() => {
    dispatch(fetchPharmacyProducts());
  }, [dispatch, loading]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setUpdatedList(
      filteredList.filter((row) => {
        const productName = row.name.toLowerCase();
        const pharmacyName = row.pharmacy.pharmacy_name.toLowerCase();
        const categoryName = row.category.name.toLowerCase();
        // const prescription = row.prescription.toString().toLowerCase()
        // const price = row.price.toString().toLowerCase();
        const search = searchTerm.toLowerCase();

        console.log("prescription: ", typeof prescription);
        return (
          productName.includes(search) ||
          pharmacyName.includes(search) ||
          categoryName.includes(search)
          // prescription.includes(search) ||
          // price.includes(search)
        );
      })
    );
  };

  console.log("updatedList", updatedList);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const toggleNewProductModal = () => {
    setNewProductModalOpen(!newProductModalOpen);
  };
  const toggleNewCSVModal = () => {
    setNewCSVModalOpen(!newCSVModalOpen);
  };

  const getPrescriptionBadge = (bool) =>
    bool ? "badge-success" : "badge-dark";

  const getPage = (index) => {
    dispatch(
      fetchPharmacyProducts({
        page: index,
      })
    );
  };

  const createNewProduct = (details) => {
    dispatch(createProduct(details));
    toggleNewProductModal();
  };
  const createNewCSVProduct = (details) => {
    // Create a function called createCSVProduct
    console.log("details: ", details);
    dispatch(createCSVProduct(details));
    toggleNewCSVModal();
  };

  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
                <div className="col">
                  <h1 className="mb-0" style={{ color: "#ea526f" }}>
                    {data.metadata.total}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      PRODUCTS
                    </span>
                  </h1>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <div className="col">
                  <Button
                    color="default"
                    // type="button"
                    // className="float-right"
                    onClick={() => setNewCSVModalOpen(true)}
                  >
                    + Upload CSV
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewCSVModal()}
                    isOpen={newCSVModalOpen}
                  >
                    <UploadCSVForm
                      handleSubmit={createNewCSVProduct}
                      toggleNewCSVModal={toggleNewCSVModal}
                    />
                  </Modal>
                  <Button
                    color="default"
                    type="button"
                    className="float-right"
                    onClick={() => setNewProductModalOpen(true)}
                  >
                    + ADD PRODUCT
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewProductModal()}
                    isOpen={newProductModalOpen}
                  >
                    <NewProductForm
                      handleSubmit={createNewProduct}
                      toggleNewProductModal={toggleNewProductModal}
                    />
                  </Modal>
                </div>
                {/* <div className="col">Add</div> */}
              </Row>
              {/* <hr /> */}
              {/* <Row className>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col float-right">
                  <Form className="navbar-search form-inline d-none d-md-flex ml-lg-auto">
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Search" />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </div>
                <div className="col">
                  <i class="fas fa-2x fa-filter"></i>
                </div>
              </Row> */}
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PHARMACY
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CATEGORY
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PRESCRIPTION
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PRICE
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((product) => {
                    return (
                      <tr key={product._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="pharmacy's profile img"
                                src={
                                  product.imageUrl?.length
                                    ? product.imageUrl[0].imgPath
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0"
                                data-test="product-name"
                                style={{ fontSize: "0.85rem" }}
                              >
                                <strong>{product.name}</strong>
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product.pharmacy?.pharmacy_name}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product.category?.name}</strong>
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getPrescriptionBadge(
                              product.prescription
                            )}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {product.prescription.toString()}
                          </Badge>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product.price}</strong>
                          </div>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {product.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(product._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {product.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(product._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() => deletePharmacy(product._id)}
                              >
                                Delete
                              </DropdownItem>

                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default PharmacyList;
