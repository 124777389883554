/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
// import Maps from "views/examples/Maps.js";
import Admins from "views/Admin/Admins";
import Doctors from "views/Doctors/Doctors";
import Patients from "views/Patients/Patients";
import Pharmacies from "views/Pharmacy/Pharmacy";
import Labs from "views/Labs/Labs";
import Adverts from "views/Advertisements/Adverts";
import Currencies from "views/Currencies/Currencies";
import Cities from "views/Cities/Cities";
import Countries from "views/Countries/Countries";
import Languages from "views/Languages/Languages";
import Appointments from "views/Appointments/Appointments";
import Reports from "views/Reports/Reports";

import Register from "views/examples/Register.js";
import Login from "views/Auth/Login";
import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";

import {
  faUserMd,
  faUserLock,
  faHospitalUser,
  faCapsules,
  faVials,
  faHospital,
  faCalendar,
  faWallet,
  faChartLine,
  faDollarSign,
  faCity,
  faFlag,
  faLanguage,
  faAd,
  faTablets,
} from "@fortawesome/free-solid-svg-icons";
import Invoices from "views/Invoices/Invoices";
import PrescriptionList from "components/Prescription/PrescriptionList";

let routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: faChartLine,
    component: Index,
    layout: "/admin",
    permissions: [],
  },
  {
    separator: true,
    name: "users",
  },
  {
    path: "/admin-accounts",
    name: "Admins",
    icon: faUserLock,
    component: Admins,
    layout: "/admin",
    permissions: ["all"],
  },
  {
    path: "/doctors",
    name: "Doctors",
    icon: faUserMd,
    component: Doctors,
    layout: "/admin",
    permissions: ["all", "doctors"],
  },
  {
    path: "/patients",
    name: "Patients",
    icon: faHospitalUser,
    component: Patients,
    layout: "/admin",
    permissions: ["all", "doctors"],
  },
  {
    separator: true,
    name: "partners",
  },
  {
    path: "/pharmacies",
    name: "Pharmacies",
    icon: faCapsules,
    component: Pharmacies,
    layout: "/admin",
    permissions: ["all", "pharmacies"],
  },
  {
    path: "/labs",
    name: "Labs",
    icon: faVials,
    component: Labs,
    layout: "/admin",
    permissions: ["all", "labs"],
  },
  {
    path: "/hospitals",
    name: "Hospitals",
    icon: faHospital,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/prescription",
    name: "Prescriptions",
    icon: faTablets,
    component: PrescriptionList,
    layout: "/admin",
    permissions: ["all"],
  },
  {
    separator: true,
    name: "accounts",
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: faCalendar,
    component: Invoices,
    layout: "/admin",
    permissions: [],
  },

  {
    separator: true,
    name: "others",
  },
  {
    path: "/appointments",
    name: "Appointments",
    icon: faCalendar,
    component: Appointments,
    layout: "/admin",
    permissions: [],
  },
  /* {
    path: "/transactions",
    name: "Transactions",
    icon: faWallet,
    component: Tables,
    layout: "/admin",
  }, */
  {
    path: "/currencies",
    name: "Currencies",
    icon: faDollarSign,
    component: Currencies,
    layout: "/admin",
    permissions: [],
  },
  {
    path: "/advertisements",
    name: "Adverts",
    icon: faAd,
    component: Adverts,
    layout: "/admin",
    permissions: [],
  },
  {
    path: "/cities",
    name: "Cities",
    icon: faCity,
    component: Cities,
    layout: "/admin",
    permissions: [],
  },
  {
    path: "/countries",
    name: "Countries",
    icon: faFlag,
    component: Countries,
    layout: "/admin",
    permissions: [],
  },
  {
    path: "/languages",
    name: "Languages",
    icon: faLanguage,
    component: Languages,
    layout: "/admin",
    permissions: [],
  },
  {
    separator: true,
    name: "Reports",
  },
  {
    path: "/airtime",
    name: "MTN Ghana Airtime",
    icon: faChartLine,
    component: Reports,
    layout: "/admin",
    permissions: [],
  },

  {
    path: "/login",
    name: "Login",
    // icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    permissions: [],
  },
  {
    path: "/register",
    name: "Register",
    // icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    permissions: [],
  },
];

/* const scope = JSON.parse(localStorage.getItem("user")).scope;

switch (scope) {
  case "pharmacies":
    routes = routes.filter();
    break;
  case "labs":
    break;
  case "doctors":
    break;
  case "patients":
    break;
  default:
    break;
} */
export default routes;
