import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TextArea,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import {
  fetchPharmacyList,
  fetchPharmacyProducts,
} from "store/slices/pharmacy";
import { fetchCategoryList } from "store/slices/pharmacyProductCategories";

const NewProductForm = ({ handleSubmit, toggleNewProductModal }) => {
  const [productDetails, setProductDetails] = useState({
    name: "",
    pharmacy_id: "",
    product_category_id: "",
    product_sub_category_id: "",
    description: "",
    price: 0,
    brand: "",
    // prescription: false,
  });
  const { pharmacies } = useSelector((state) => state.pharmacies.pharmacyList);
  const { pharmacy_products } = useSelector(
    (state) => state.pharmacies.pharmacyProductsList
  );

  const { pharmacy_categories } = useSelector(
    (state) => state.pharmacyProductCategories.categoriesList
  );

  const { wait } = useSelector((state) => state.pharmacyProductCategories);

  const [pharmacyId, setPharmacyId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [pharmacyCategoryId, setPharmacyCategoryId] = useState("");
  const [pharmacySubCategoryId, setPharmacySubCategoryId] = useState("");

  const [name, setName] = useState("");

  // console.log("pharmacy_categories", pharmacy_categories)
  // console.log("pharmacies", pharmacies)
  console.log("wait", wait);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPharmacyList());
    dispatch(fetchPharmacyProducts());
    dispatch(fetchCategoryList());
  }, [dispatch]);

  const handleChange = (event) => {
    const {
      name,
      value,
      checked,
      pharmvalue,
      catvalue,
      subcatvalue,
      brandvalue,
    } = event.target;
    setPharmacyId(pharmvalue);
    setPharmacyCategoryId(catvalue);
    setPharmacySubCategoryId(subcatvalue);
    setBrandId(brandvalue);
    setProductDetails({
      ...productDetails,
      [name]: name === "prescription" ? checked : value,
      // [name]: name === "price" ? +value : value
      // price: value
    });
  };

  console.log("productDetails", productDetails);

  const submitForm = () => {
    handleSubmit(productDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewProductModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New Pharmacy Product</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label
                  style={{ fontSize: "0.85rem" }}
                  for="product_category_id"
                >
                  <strong>Product Category</strong>
                </Label>
                <Input
                  id="product_category_id"
                  name="product_category_id"
                  type="select"
                  className="form-control-alternative"
                  onChange={handleChange}
                  catvalue={pharmacyCategoryId}
                >
                  <option value="" disabled selected>
                    Select Product Category
                  </option>
                  {pharmacy_categories &&
                    pharmacy_categories.map((category) => (
                      <option value={category._id} key={category._id}>
                        {category.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <FormGroup>
                <Label
                  style={{ fontSize: "0.85rem" }}
                  for="product_sub_category_id"
                >
                  <strong>Product Sub Category</strong>
                </Label>
                <Input
                  id="product_sub_category_id"
                  name="product_sub_category_id"
                  type="select"
                  className="form-control-alternative"
                  onChange={handleChange}
                  subcatvalue={pharmacySubCategoryId}
                >
                  <option value="" disabled selected>
                    Select Product Sub Category
                  </option>
                  {/* <option value={category._id} key={category._id}>
                        {category.name}
                      </option> */}
                  /* 638f90a8da78d1afb197d28f talk say duplicate key dey. Query
                  that endpoint man */
                  {pharmacy_categories &&
                    pharmacy_categories
                      .map((category) => category.sub_categories[0])
                      .map((sub_category, index) => (
                        <option value={sub_category._id} key={index}>
                          {sub_category.name}
                        </option>
                      ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="pharmacy_id">
                  <strong>Pharmacy</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="pharmacy_id"
                  name="pharmacy_id"
                  type="select"
                  className="form-control-alternative"
                  pharmvalue={pharmacyId}
                  // onClick={() => updateId(id)}
                >
                  <option value="" disabled selected>
                    Select Pharmacy
                  </option>
                  {pharmacies &&
                    pharmacies.map((pharma) => {
                      return (
                        <option value={pharma._id} key={pharma._id}>
                          {pharma.pharmacy_name}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="pharmacy_id">
                  <strong>Brand</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="brand"
                  name="brand"
                  type="select"
                  className="form-control-alternative"
                  brandvalue={brandId}
                  // onClick={() => updateId(id)}
                >
                  <option value="" disabled selected>
                    Select Brand
                  </option>
                  {pharmacy_products &&
                    pharmacy_products.map((pharma) => {
                      // console.log("pharma",pharma)
                      return (
                        <option value={pharma._id} key={pharma._id}>
                          {pharma.brand?.name}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Product Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  value={productDetails.name}
                  placeholder="Product Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Price</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="price"
                  placeholder="Price"
                  value={productDetails.price}
                  name="price"
                  type="number"
                  min="1"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="description"
                  name="description"
                  value={productDetails.description}
                  type="textarea"
                  placeholder="Product Description..."
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="my-2">
              <FormGroup check>
                <Input
                  type="checkbox"
                  onChange={handleChange}
                  id="prescription"
                  name="prescription"
                  className="form-control-alternative"
                />
                <Label check style={{ fontSize: "0.85rem" }} for="prescription">
                  <strong>Requires Prescription?</strong>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewProductModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default NewProductForm;
