import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

import NewCurrencyForm from "components/Currencies/modals/NewCurrencyForm";
import { fetchCurrenciesList, createNewCurrency } from "store/slices/currency";

import { formatDate } from "utils/date";

const CurrenciesList = (props) => {
  const [newCurrencyModalOpen, setNewCurrencyModalOpen] = useState(false);
  const data = useSelector((state) => state.currencies.currenciesList);
  const loading = useSelector((state) => state.currencies.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrenciesList());
  }, [dispatch, loading]);

  /* const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };
 */

  const toggleNewCurrencyModal = () => {
    setNewCurrencyModalOpen(!newCurrencyModalOpen);
  };

  const getPage = (index) => {
    dispatch(
      fetchCurrenciesList({
        page: index,
      })
    );
  };
  const createCurrency = (details) => {
    dispatch(createNewCurrency(details));
    toggleNewCurrencyModal();
  };
  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.currencies.length}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        CURRENCIES
                      </span>
                    </h1>
                  </div>
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewCurrencyModalOpen(true)}
                    >
                      + ADD NEW CUERRENCY
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewCurrencyModal()}
                      isOpen={newCurrencyModalOpen}
                    >
                      <NewCurrencyForm
                        handleSubmit={createCurrency}
                        toggleNewCurrencyModal={toggleNewCurrencyModal}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      NAME
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CODE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CREATED ON
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.currencies.map((currency) => {
                    return (
                      <tr key={currency._id}>
                        <td>
                          <span
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{currency.currency_name}</strong>
                          </span>
                        </td>
                        <td>
                          <span style={{ fontSize: "0.85rem" }}>
                            <strong>{currency.currency_code}</strong>
                          </span>
                        </td>
                        <td>
                          <span style={{ fontSize: "0.85rem" }}>
                            {formatDate(currency.createdAt)}
                          </span>
                        </td>
                        {/* <td className="text-left">
                          <Badge
                            className={getBadgeColor(currency.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {currency.status}
                          </Badge>
                        </td> */}
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                      metadata={data.metadata}
                      getPage={getPage}
                    ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CurrenciesList;
