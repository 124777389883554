import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { differenceInYears } from "date-fns";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  // Badge,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import { getDoctorProfile } from "services/api/doctors";
import DoctorAccountDetails from "./DoctorProfileAccountDetails";

const DoctorProfile = () => {
  const { doctorId } = useParams();
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState();
  const [activeTab, setActiveTab] = useState("account");
  // Dispatch Get Profile

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getDoctorProfile(doctorId);
      setDoc(response.doctor);
    })();
    setLoading(false);
  }, []);

  const tabs = [
    { name: "account" },
    { name: "appointments" },
    { name: "reviews" },
  ];

  const getAge = (dob) => {
    return differenceInYears(new Date(), new Date(dob));
  };

  return (
    <>
      <UserHeader user={doc} loading={loading} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {doc && (
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={doc.profile_image}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    {/* <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button> */}
                    {/* <Badge
                      className={
                        "float-right " + getBadgeColor(doc.account_status)
                      }
                      pill
                      style={{
                        fontSize: "0.77rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {doc.account_status}
                    </Badge> */}
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">0</span>
                          <span className="description">Appointments</span>
                        </div>
                        <div>
                          <span className="heading">0</span>
                          <span className="description">Reviews</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {doc.first_name?.toUpperCase()}{" "}
                      {doc.last_name?.toUpperCase()}
                      {", "}
                      MD
                      <br />
                      <span className="font-weight-light">
                        {doc.date_of_birth ? getAge(doc.date_of_birth) : ""}
                      </span>
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {doc.location.city ? doc.location.city + ", " : ""}
                      {doc.location.country}
                    </div>
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Practice Certificate {" "}
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                    <br />
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      National ID {" "}
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a> */}
                    {/* <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                    <hr className="my-4" />
                    <p>
                      Ryan — the name taken by Melbourne-raised, Brooklyn-based
                      Nick Murphy — writes, performs and records all of his own
                      music.
                    </p>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Show more
                    </a> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="text-center">
                    {/* <Col xs="8">
                      <h3 className="mb-0">Account Details</h3>
                    </Col> */}
                    <Nav
                      className="nav-fill flex-column flex-sm-row"
                      id="tabs-text"
                      pills
                      role="tablist"
                    >
                      {tabs.map((tab, id) => (
                        <NavItem key={id}>
                          <NavLink
                            // aria-selected={this.state.navPills === 1}
                            // className={classnames("mb-sm-3 mb-md-0", {
                            //   // active: this.state.navPills === 1
                            // })}
                            // onClick={e => this.toggleNavs(e, "navPills", 1)}
                            href="#pablo"
                            role="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTab(tab.name);
                            }}
                            className={activeTab === tab.name ? "active" : ""}
                          >
                            <h5 className="mb-0 heading-small">{tab.name}</h5>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    {/* <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <DoctorAccountDetails doc={doc} loading={loading} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default DoctorProfile;
