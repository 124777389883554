import React, { useState } from "react";
import { Container } from "reactstrap";

import LabsList from "components/Labs/LabsList";
import TestCategoryList from "components/Labs/TestCategoriesList";
import LabsOrdersList from "components/Labs/LabsOrdersList";
import LabsProductsList from "components/Labs/LabsProductsList";
import TestKitList from "components/Labs/TestKitList";
import WellnessPackagesList from "components/Labs/WellnessPackagesList";

const tabs = [
  "Orders",
  "Labs",
  "Product Categories",
  "Tests",
  "Test Kits",
  "Wellness Packages",
];

const Labs = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const renderContent = () => {
    switch (activeTab) {
      case "Labs":
        return <LabsList></LabsList>;
      case "Product Categories":
        return <TestCategoryList></TestCategoryList>;
      case "Orders":
        return <LabsOrdersList></LabsOrdersList>;
      case "Tests":
        return <LabsProductsList></LabsProductsList>;
      case "Test Kits":
        return <TestKitList></TestKitList>;
      case "Wellness Packages":
        return <WellnessPackagesList></WellnessPackagesList>;
      default:
        break;
    }
  };

  return (
    <Container fluid>
      <nav className="nav nav-pills nav-justified pt-7">
        {tabs.map((tab) => {
          return (
            <a
              key={tab}
              className={`nav-link mr-2 ${activeTab === tab ? "active" : null}`}
              href={`#${tab}`}
              onClick={() => {
                setActiveTab(tab);
              }}
            >
              <strong>{tab}</strong>
            </a>
          );
        })}
      </nav>
      <>{renderContent()}</>
    </Container>
  );
};

export default Labs;
