/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Link, useHistory } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const UserHeader = ({ user }) => {
  const history = useHistory();

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage: `url("${user?.profile_image || ""}")`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-primary opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12" className="my-3">
              <Button
                color="default"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/admin/doctors");
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back To Doctors' List
              </Button>
            </Col>
            <Col lg="12" md="12">
              {user && (
                <>
                  <h1 className="display-2 text-white">
                    {user.first_name?.toUpperCase()}{" "}
                    {user.last_name?.toUpperCase()}
                    {", MD"}
                  </h1>
                  <p className="text-white mt-0 mb-5">{user.bio}</p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
