import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Media,
  Table,
  Container,
  Row,
  Button,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

import { fetchReportsList } from "store/slices/reports";
import { formatDate } from "utils/date";
import SearchFilter from "components/SearchFilter/SearchFilter";

const ReportsList = () => {
  const data = useSelector((state) => state.reports.reportsList);
  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const loading = useSelector((state) => state.reports.loading);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReportsList());
  }, []);

  useEffect(() => {
    setFilteredList(data);
    setUpdatedList(data);
  }, [data, searchTerm]);

  console.log("updatedList", updatedList);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // const upd =
    setUpdatedList(
      filteredList?.filter((row) => {
        const owner = row.owner.toLowerCase();
        const sub_type = row.sub_type.toLowerCase();
        const bot_Name = row.bot_Name.toLowerCase();
        const phone_number = row.phone_number.toLowerCase();
        const created_at = row.createdAt;
        const amount = row.amount;
        const search = searchTerm.toLowerCase();

        return (
          owner.includes(search) ||
          sub_type.includes(search) ||
          bot_Name.includes(search) ||
          phone_number.includes(search) ||
          created_at.includes(search) ||
          amount.includes(search)
        );
      })
    );
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "completed":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  // Remember to implements this
  // updatePharmacyProfile,
  // deletePharmacyAccount,
  // createPharmacyAccount,

  const toggleNewReportModal = () => {
    setNewReportModalOpen(!newReportModalOpen);
  };

  const createNewReport = (details) => {
    dispatch(createReportAccount(details));
    toggleNewReportModal();
  };

  const updateStatus = (reportId, status) => {
    dispatch(
      updateReportProfile({
        reportId,
        status: status,
      })
    );
  };

  const deletePhamarcy = (reportId) => {
    dispatch(deleteReportAccount(reportId));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.length}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        MTN GHANA AIRTIME
                      </span>
                    </h1>
                  </div>
                  <SearchFilter
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleChange={handleChange}
                  />
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      OWNER
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      SUB TYPE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      BOT NAME
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CONTACT
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      PLACED ON
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      AMOUNT
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      STATUS
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {updatedList &&
                    updatedList?.map((report) => {
                      return (
                        <tr key={report.id}>
                          <td scope="row">
                            <span className="mb-0 text-sm">{report.owner}</span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {report.sub_type}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {report.bot_Name}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {report.phone_number}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {formatDate(report.createdAt)}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {report.amount}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              <Badge
                                className={getBadgeColor(report.status)}
                                pill
                                style={{
                                  fontSize: "0.77rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                {report.status}
                              </Badge>
                            </span>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {report.status !== "completed" && (
                                  <DropdownItem
                                    onClick={() =>
                                      updateStatus(report._id, "completed")
                                    }
                                  >
                                    Set as Pending
                                  </DropdownItem>
                                )}
                                {report.status !== "pending" && (
                                  <DropdownItem
                                    onClick={() =>
                                      updateStatus(report._id, "pending")
                                    }
                                  >
                                    Set as Completed
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  onClick={() => deletePhamarcy(pharm._id)}
                                >
                                  Delete
                                </DropdownItem>

                                {/* <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ReportsList;
