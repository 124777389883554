import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchDoctors,
  updateDoctor,
  getDoctorProfile,
  deleteDoctor,
  signUpDoctor,
  uploadVerificationDocuments,
} from "../../services/api/doctors";

export const fetchDoctorsList = createAsyncThunk(
  "doctors/fetchList",
  async (params) => {
    const response = await fetchDoctors(params);
    return response;
  }
);

export const createDoctorAccount = createAsyncThunk(
  "doctors/createAccount",
  async (params) => {
    const response = await signUpDoctor(params);
    return response;
  }
);

export const updateDoctorProfile = createAsyncThunk(
  "doctors/updateProfile",
  async (params) => {
    const response = await updateDoctor(params);
    return response;
  }
);

export const deleteDoctorAccount = createAsyncThunk(
  "doctors/deleteAccount",
  async (params) => {
    const response = await deleteDoctor(params);
    return response;
  }
);

export const updateVerificationDetails = createAsyncThunk(
  "doctors/updateVerificationDetails",
  async (params) => {
    const response = await uploadVerificationDocuments(params);
    return response;
  }
);

export const doctorsSlice = createSlice({
  name: "doctors",
  initialState: {
    loading: false,
    doctorProfile: {},
    doctorsList: {
      doctors: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDoctorsList.fulfilled, (state, action) => {
      state.doctorsList = action.payload;
    });

    builder.addCase(updateDoctorProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateDoctorProfile.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteDoctorAccount.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteDoctorAccount.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateVerificationDetails.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default doctorsSlice.reducer;
