import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,
  Button,
  // UncontrolledTooltip,
} from "reactstrap";
import { toast } from "react-toastify";

import NewInvoiceForm from "components/Payments/Modals/NewInvoiceModal";
import Pagination from "components/Pagination/Pagination";
import { createNewInvoice, fetchInvoicesList } from "store/slices/invoices";
import { formatDate } from "utils/date";
import { fetchCountriesList } from "store/slices/countries";
import { fetchCurrenciesList } from "store/slices/currency";
import SearchFilter from "components/SearchFilter/SearchFilter";
import ReactPaginate from "react-paginate";

const InvoiceList = (props) => {
  const [newInvoiceModalOpen, setNewInvoiceModalOpen] = useState(false);
  let data = useSelector((state) => state.invoices.invoicesList);
  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  console.log("data.invoices", data.invoices);
  let re = data.invoices;
  console.log("typeof ", [...data.invoices].reverse());

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    setFilteredList(data.invoices);
    setUpdatedList(data.invoices);
  }, [data.invoices, page, searchTerm]);

  // console.log('page', page)

  const total = data.metadata.total;
  const limit = 10;

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // const upd =
    setUpdatedList(
      filteredList.filter((row) => {
        const customerName = row.customerName.toLowerCase();
        const customerEmail = row.customerEmail.toLowerCase();
        const country = row.country.toLowerCase();
        const service = row.service.toLowerCase();
        const preparedBy = row.preparedBy.name.toLowerCase();
        const platform = row.platform ? row.platform.toLowerCase() : "unknown";
        const search = searchTerm.toLowerCase();

        return (
          customerName.includes(search) ||
          customerEmail.includes(search) ||
          country.includes(search) ||
          service.includes(search) ||
          preparedBy.includes(search) ||
          platform.includes(search)
        );
      })
    );
  };

  const handlePageChange = (e) => {
    // console.log('e.selected', e.selected + 1)
    setPage(e.selected + 1);
  };
  const loading = useSelector((state) => state.invoices.loading);
  const countries = useSelector(
    (state) => state.countries.countriesList.countries
  );
  const currencies = useSelector(
    (state) => state.currencies.currenciesList.currencies
  );
  // console.log(currencies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInvoicesList(page));
  }, [dispatch, loading, page]);

  // useEffect(() => {
  //   dispatch(fetchCountriesList());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCurrenciesList());
  }, [dispatch, filteredList]);

  useEffect(() => {
    dispatch(fetchCountriesList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCurrenciesList());
  }, [dispatch, filteredList]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "settled":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "cancelled":
        return "badge-dark";
      default:
        break;
    }
  };
  const toggleNewInvoiceModal = () => {
    setNewInvoiceModalOpen(!newInvoiceModalOpen);
  };

  const getPage = (index) => {
    dispatch(
      fetchInvoicesList({
        page: index,
      })
    );
  };

  const createNew = (details) => {
    try {
      dispatch(createNewInvoice(details));
      toggleNewInvoiceModal();
      toast.success("Invoice added");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {/* {data.metadata.total}{" "} */}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        INVOICES
                      </span>
                    </h1>
                  </div>
                  <SearchFilter
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleChange={handleChange}
                  />
                  {/* <SearchFilter filteredSearch={filteredSearch} /> */}
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewInvoiceModalOpen(true)}
                    >
                      + CREATE INVOICE
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewInvoiceModal()}
                      isOpen={newInvoiceModalOpen}
                    >
                      <NewInvoiceForm
                        handleSubmit={createNew}
                        toggleNewInvoiceModal={toggleNewInvoiceModal}
                        countries={countries}
                        currencies={currencies}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Name
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Contact
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Payment Link
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Service
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Created On
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Created By
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Source
                    </th>

                    {/* <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Status
                    </th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {updatedList &&
                    updatedList.map((invoice) => {
                      // {filteredList.map((invoice) => {
                      return (
                        <tr key={invoice._id}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span
                                  className="mb-0 text-sm"
                                  data-test="invoice-name"
                                >
                                  {invoice.customerName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            <div style={{ fontSize: "0.90rem" }}>
                              <strong>{invoice.customerEmail || "--"}</strong>
                            </div>
                            <div
                              style={{ color: "#8898aa", fontSize: "0.75rem" }}
                            >
                              {invoice.customerNumber || "--"}
                            </div>
                          </td>
                          <td>
                            <div>
                              <a
                                className=""
                                role="button"
                                onClick={() =>
                                  invoice.paymentLink
                                    ? copy(invoice.paymentLink)
                                    : false
                                }
                              >
                                {"Click to copy"}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div>
                              <strong>{invoice.service}</strong>
                            </div>
                          </td>
                          <td>
                            <div style={{ fontSize: "0.75rem" }}>
                              {formatDate(invoice.createdAt)}
                            </div>
                          </td>
                          <td>
                            <div>{invoice.preparedBy.name}</div>
                          </td>
                          <td>
                            <div style={{ textTransform: "capitalize" }}>
                              {invoice.platform ? invoice.platform : "Unknown"}
                            </div>
                          </td>
                          {/* <td className="text-left">
                          <Badge
                            className={getBadgeColor(invoice.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {invoice.status}
                          </Badge>
                        </td> */}
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    window.open(invoice.fileLink, "_blank");
                                  }}
                                >
                                  Preview Document
                                </DropdownItem>
                                <DropdownItem onClick={() => {}}>
                                  Send
                                </DropdownItem>
                                {/* {invoice.status !== "settled" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(invoice._id, "settled")
                                  }
                                >
                                  Mark as Settled
                                </DropdownItem>
                              )}
                              {invoice.status !== "pending" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(invoice._id, "pending")
                                  }
                                >
                                  Mark as Pending
                                </DropdownItem>
                              )}
                              {invoice.status !== "cancelled" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(invoice._id, "cancelled")
                                  }
                                >
                                  Cancel
                                </DropdownItem>
                              )} */}
                                {/* <DropdownItem
                                onClick={() => deleteInvoice(invoice._id)}
                              >
                                Delete
                                </DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel="<<"
                  nextLabel=">>"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  pageCount={Math.ceil(total / limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={1}
                  onPageChange={(e) => handlePageChange(e)}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default InvoiceList;
