import client from "services/axios";

export async function fetchAdmins(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(`/auth/admin_list?page=${params.page}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("[DOCTOR FETCH ERR]:", error);
    throw new Error("An error occured while fetching admins");
  }
}
