import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchCountries,
  editCountry,
  deleteCountry,
  createCountry,
} from "../../services/api/countries";

export const fetchCountriesList = createAsyncThunk(
  "countries/fetchList",
  async (params) => {
    const response = await fetchCountries(params);
    return response;
  }
);
export const createNewCountry = createAsyncThunk(
  "countries/createCountry",
  async (params) => {
    const response = await createCountry(params);
    return response;
  }
);

export const updateCountryStatus = createAsyncThunk(
  "countries/updateStatus",
  async (params) => {
    const response = await editCountry(params);

    return response;
  }
);

export const deleteCountryAccount = createAsyncThunk(
  "countries/deleteCountry",
  async (params) => {
    const response = await deleteCountry(params);
    return response;
  }
);

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    loading: false,
    countriesList: {
      countries: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesList.fulfilled, (state, action) => {
      state.countriesList.countries = action.payload.country;
      state.countriesList.metadata = action.payload.metadata;
    });
    builder.addCase(updateCountryStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateCountryStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCountryAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCountryAccount.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default countriesSlice.reducer;
