import client from "../axios.js";

export async function fetchCities(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(`/city?page=${params.page}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("[CITES FETCH ERR]:", error);
    throw new Error("An error occured while fetching cities");
  }
}

export async function editCity(data) {
  try {
    const { cityId, ...updates } = data;

    await client.put(`/city/edit/${cityId}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("[CITY EDIT ERR]:", error);
    throw new Error("An error occured while editing a city");
  }
}

export async function deleteCity(id) {
  try {
    const response = await client.delete(`/city${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a city");
  }
}

export async function createCity(city) {
  try {
    const response = await client.post("/city/", city, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("CITY CREATION ERR]:", error);
    throw new Error("An error occured while creating a city");
  }
}
