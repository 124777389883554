import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  // UncontrolledTooltip,
} from "reactstrap";
import { advertsSlice } from "store/slices/advertisements";

// import Pagination from "../Pagination/Pagination";
import { fetchAdvertsList } from "store/slices/advertisements";

const AdvertsList = (props) => {
  const data = useSelector((state) => state.adverts.advertsList);
  const loading = useSelector((state) => state.adverts.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdvertsList());
  }, [dispatch, loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const getPage = (index) => {
    dispatch(
      fetchAdvertsList({
        page: index,
      })
    );
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="mb-0" style={{ color: "#ea526f" }}>
                  {data.adverts.length}{" "}
                  <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                    ADVERTISEMENTS
                  </span>
                </h1>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      BANNER
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      TITLE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      ADDRESS
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      STATUS
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.adverts.map((ad) => {
                    return (
                      <tr key={ad._id}>
                        <th scope="row" style={{ maxWidth: "1.90rem" }}>
                          <Media className="align-items-center">
                            <img
                              alt="advert's img"
                              className="rounded img-thumbnail"
                              src={
                                ad.image.length
                                  ? ad.image
                                  : require("../../assets/img/theme/avatar.jpeg")
                              }
                              style={{ maxHeight: "4.90rem" }}
                            />
                          </Media>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong className="mb-0 text-sm">{ad.title}</strong>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{ad.location.city}</strong>
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(ad.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {ad.status}
                          </Badge>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                      metadata={data.metadata}
                      getPage={getPage}
                    ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AdvertsList;
