import axios from "axios";
// import { getToken } from "../utils/auth";

const baseURL =
  process.env.REACT_APP_ZURI_HEALTH_API || //"http://localhost:3000/api/v2";
  "https://api.zurihealthadmin.com/api/v2"; //  Staging build(develop)
// process.env.REACT_APP_ZURI_HEALTH_API ||
// "https://api.zurihealthadmin.com/api/v2"; // Production build(master)

// const Authorization = getToken();

const client = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    // Authorization,
  },
});

export default client;
