import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,

  // UncontrolledTooltip,
} from "reactstrap";

import NewCityForm from "components/Cities/modals/NewCityForm";

// import Pagination from "../Pagination/Pagination";
import {
  fetchCitiesList,
  updateCityStatus,
  deleteCityAccount,
  createNewCity,
} from "store/slices/cities";
import { formatDate } from "utils/date";

const CityList = (props) => {
  const [newCityModalOpen, setNewCityModalOpen] = useState(false);
  const data = useSelector((state) => state.cities.citiesList);
  const loading = useSelector((state) => state.cities.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCitiesList());
  }, [dispatch, loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };
  const toggleNewCityModal = () => {
    setNewCityModalOpen(!newCityModalOpen);
  };
  const getPage = (index) => {
    dispatch(
      fetchCitiesList({
        page: index,
      })
    );
  };

  const updateStatus = (cityId, status) => {
    dispatch(
      updateCityStatus({
        cityId,
        status: status,
      })
    );
  };

  const deleteCityProfile = (cityId) => {
    dispatch(deleteCityAccount(cityId));
  };
  const createCity = (details) => {
    dispatch(createNewCity(details));
    toggleNewCityModal();
  };
  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.cities.length}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        CITIES
                      </span>
                    </h1>
                  </div>
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewCityModalOpen(true)}
                    >
                      + ADD NEW CITY
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewCityModal()}
                      isOpen={newCityModalOpen}
                    >
                      <NewCityForm
                        handleSubmit={createCity}
                        toggleNewCityModal={toggleNewCityModal}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      NAME
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CODE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      COUNTRY
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CREATED ON
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.cities.map((city) => {
                    return (
                      <tr key={city._id}>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{city.city_name}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{city.city_code.toUpperCase()}</strong>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{city.country?.country_name}</strong>
                          </div>
                        </td>
                        <td>
                          <span style={{ fontSize: "0.85rem" }}>
                            {formatDate(city.createdAt)}
                          </span>
                        </td>
                        {/* <td className="text-left">
                          <Badge
                            className={getBadgeColor(city.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {city.status}
                          </Badge>
                        </td> */}
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {/* {city.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(city._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {city.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(city._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )} */}
                              <DropdownItem
                                onClick={() => deleteCityProfile(city._id)}
                              >
                                Delete
                              </DropdownItem>

                              {/* <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CityList;
