import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TextArea,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from "reactstrap";
import { fetchLabsList } from "store/slices/labs";
import { fetchCategoryList } from "store/slices/testCategories";

const NewTestKitModal = ({ handleSubmit, toggleNewTestKitModal }) => {
  const [testKitDetails, setTestKitDetails] = useState({
    name: "",
    test_center_id: "",
    test_kit_category_id: "",
    description: "",
    available: true,
    price: 0,
    discount: 0,
    image: null,
  });

  const { test_centers } = useSelector((state) => state.labs.labsList);
  const { test_categories } = useSelector(
    (state) => state.testCategories.categoriesList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsList());
    dispatch(fetchCategoryList());
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTestKitDetails({
      ...testKitDetails,
      [name]: value,
    });
  };

  const submitForm = () => {
    handleSubmit(testKitDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewTestKitModal()}>
        <strong style={{ fontSize: "1.00rem" }}>Add New Test Kit</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label
                  style={{ fontSize: "0.85rem" }}
                  for="test_kit_category_id"
                >
                  <strong>Test Kit Category</strong>
                </Label>
                <Input
                  id="test_kit_category_id"
                  name="test_kit_category_id"
                  type="select"
                  className="form-control-alternative"
                  onChange={handleChange}
                >
                  <option defaultValue="" disabled selected>
                    Select Test Category
                  </option>
                  {test_categories &&
                    test_categories.map((category) => (
                      <option
                        defaultValue={
                          // category._id
                          "5f74342cde37b520a5b535dc"
                        }
                        key={category._id}
                      >
                        {category.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_center_id">
                  <strong>Test Centre</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="test_center_id"
                  name="test_center_id"
                  type="select"
                  className="form-control-alternative"
                >
                  <option defaultValue="" disabled selected>
                    Select Test Centre
                  </option>
                  {test_centers &&
                    test_centers.map((lab) => (
                      <option defaultValue={lab._id} key={lab._id}>
                        {lab.center_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Test Kit Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder="Test Kit Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Price</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="price"
                  placeholder="Price"
                  name="price"
                  type="number"
                  min="1"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="description"
                  name="description"
                  type="textarea"
                  placeholder="Product Description..."
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="discount">
                  <strong>Discount</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="discount"
                  placeholder="discount"
                  name="discount"
                  type="number"
                  max="100"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="image">
                  <strong>Image URL</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="image"
                  name="image"
                  placeholder="Image URL"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="my-2">
              <FormGroup check>
                <Input
                  type="checkbox"
                  onChange={handleChange}
                  id="available"
                  name="available"
                  className="form-control-alternative"
                  disabled
                />
                <Label check style={{ fontSize: "0.85rem" }} for="available">
                  <strong>Is Kit available ?</strong>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewTestKitModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default NewTestKitModal;
