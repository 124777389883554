import client from "../axios";

export async function createTestCategory(category) {
  try {
    const response = await client.post("/test_category/category", category, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("TEST CATEGORY CREATION ERR]:", error);
    throw new Error("An error occured while creating a test category");
  }
}

export async function fetchTestCategories(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_category/category_list?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("[Test categories Fetch Error]:", error);
    throw new Error("An error occured while fetching test categories");
  }
}

export async function editTestCategory(data) {
  try {
    const { categoryId, ...updates } = data;

    await client.put(
      `/test_category/category_profile_edit/${categoryId}`,
      updates,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log("[TEST CATEGORY EDIT ERR]:", error);
    throw new Error("An error occured while editing a test category");
  }
}

export async function deleteTestCategory(id) {
  try {
    const response = await client.delete(
      `test_category/category_delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a test category");
  }
}
