import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { fetchCitiesList } from "store/slices/cities";
import { fetchCurrenciesList } from "store/slices/currency";
import { fetchCountriesList } from "store/slices/countries";

const NewLabForm = ({ handleSubmit, toggleNewLabModal }) => {
  const [labDetails, setLabDetails] = useState({
    center_name: "",
    partner: "",
    local_currency: "",
    email: "all",
    country_code: "",
    mobile_no: "",
    open_hr: "",
    close_hr: "",
    location: {
      coordinates: "",
      country: "",
      city: "",
      address: "",
    },
  });
  const { cities } = useSelector((state) => state.cities.citiesList);
  const { currencies } = useSelector(
    (state) => state.currencies.currenciesList
  );
  const { countries } = useSelector((state) => state.countries.countriesList);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLabDetails({ ...labDetails, [name]: value });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCitiesList());
    dispatch(fetchCurrenciesList());
    dispatch(fetchCountriesList());
  }, [dispatch]);

  const submitForm = () => {
    handleSubmit(labDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewLabModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New Test Center</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="center_name">
                  <strong>Test Center Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="center_name"
                  name="center_name"
                  placeholder="Test Center Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="partner">
                  <strong>Partner</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="partner"
                  name="partner"
                  placeholder="Partner"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="local_currency">
                  <strong>Local Currency</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="local_currency"
                  name="local_currency"
                  type="select"
                  className="form-control-alternative"
                >
                  <option value="" disabled selected>
                    Select Currency
                  </option>
                  {currencies &&
                    currencies.map((currency) => (
                      <option value={currency._id} key={currency._id}>
                        {currency.currency_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="email">
                  <strong>Email</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="email"
                  name="email"
                  placeholder="email@email.com"
                  type="email"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="country_code">
                  <strong>Country Code</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="country_code"
                  name="country_code"
                  placeholder="Country Code"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="mobile_no">
                  <strong>Mobile Number</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="mobile_no"
                  name="mobile_no"
                  placeholder="71111111"
                  type="number"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="open_hr">
                  <strong>Open Hour</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="open_hr"
                  name="open_hr"
                  placeholder="Open Hour"
                  type="time"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="close_hr">
                  <strong>Closing Hour</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="close_hr"
                  name="close_hr"
                  placeholder="Close Hour"
                  type="time"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="location">
                  <strong>Input Coordinates</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="location.coordinates"
                  name="location.coordinates"
                  placeholder="Latitude, Longitude"
                  type="location.coordinates"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="country">
                  <strong>Country</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="location.country"
                  name="location.country"
                  type="select"
                >
                  <option value="" disabled selected>
                    Select Country
                  </option>
                  {countries &&
                    countries.map((country) => (
                      <option value={country._id} key={country._id}>
                        {country.country_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="city">
                  <strong>City</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="location.city"
                  name="location.city"
                  type="select"
                >
                  <option value="" disabled selected>
                    Select City
                  </option>
                  {cities &&
                    cities.map((city) => (
                      <option value={city._id} key={city._id}>
                        {city.city_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="location">
                  <strong>Enter Address</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="location.address"
                  name="location.address"
                  placeholder="Input Address"
                  type="location.address"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewLabModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default NewLabForm;
