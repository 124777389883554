import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from "reactstrap";
import { updatePharmacyCategory } from "store/slices/pharmacyProductCategories";

import { fetchCategoryList } from "store/slices/pharmacyProductCategories";
import client from "../../../services/axios";

const UpdatePharmCategoryForm = ({
  handleSubmit,
  toggleNewPharmCategoryModal,
  activeCategory,
}) => {
  const [pharmCategoryDetails, setPharmCategoryDetails] = useState({
    name: activeCategory?.name,
    description: activeCategory?.description,
    sub_categories:
      activeCategory?.sub_categories.map((item) => item._id) || [],
    image: activeCategory?.image,
    id: activeCategory?._id,
  });

  const [subCategories, setSubCategories] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPharmCategoryDetails({ ...pharmCategoryDetails, [name]: value });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategoryList());
  }, [dispatch]);

  const submitForm = () => {
    dispatch(updatePharmacyCategory(pharmCategoryDetails));
    toggleNewPharmCategoryModal();
  };

  useEffect(() => {
    const handleFetchSubCategories = async () => {
      try {
        const { data } = await client.get(
          "/pharmacy_sub_category/sub_category_list"
        );
        setSubCategories(data.pharmacy_sub_categories);
      } catch (error) {
        console.log(error);
      }
    };
    handleFetchSubCategories();
  }, []);

  const handleChangeSelectedSubcategories = (item) => {
    if (pharmCategoryDetails.sub_categories.includes(item) && !item) {
      return;
    }
    setPharmCategoryDetails({
      ...pharmCategoryDetails,
      sub_categories: [...pharmCategoryDetails.sub_categories, item],
    });
  };

  const renderSelectedSubCategories = () => {
    return (
      pharmCategoryDetails.sub_categories.length > 0 &&
      pharmCategoryDetails.sub_categories?.map((item) => {
        const fullItem =
          subCategories.length > 0 &&
          subCategories.filter((category) => category._id === item)[0];

        return (
          <div
            key={fullItem._id}
            className="d-flex justify-content-between mt-4 align-items-center "
          >
            <p style={{ fontSize: "0.85rem" }}>
              <strong>{fullItem?.name}</strong>
            </p>
            <Button onClick={() => handleRemoveFromList(fullItem._id)}>
              Remove
            </Button>
          </div>
        );
      })
    );
  };
  const handleRemoveFromList = (item) => {
    const newSelected = pharmCategoryDetails.sub_categories?.filter(
      (itm) => itm !== item
    );

    setPharmCategoryDetails({
      ...pharmCategoryDetails,
      sub_categories: newSelected,
    });
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewPharmCategoryModal()}>
        <strong style={{ fontSize: "1.00rem" }}>Update Category</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Category Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder="Category Name"
                  type="text"
                  value={pharmCategoryDetails.name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Image url</strong>
                </Label>
                <Input
                  placeholder="Pate the image url"
                  type="text"
                  onChange={(e) =>
                    setPharmCategoryDetails({
                      ...pharmCategoryDetails,
                      image: e.target.value,
                    })
                  }
                  value={pharmCategoryDetails.image}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="description"
                  name="description"
                  placeholder="Description"
                  type="textarea"
                  value={pharmCategoryDetails.description}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>SubCategories</strong>
                </Label>
                <Input
                  className="form-control-alternative"
                  type="select"
                  onChange={(e) =>
                    handleChangeSelectedSubcategories(e.target.value)
                  }
                >
                  <option value="">Select a sub-category</option>
                  {subCategories.length > 0 &&
                    subCategories?.map((sc) => (
                      <option value={sc._id}>{sc.name}</option>
                    ))}
                </Input>

                <Row>
                  <Col md={12} className="d-flex mt-4 flex-column">
                    {renderSelectedSubCategories()}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewPharmCategoryModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};
export default UpdatePharmCategoryForm;
