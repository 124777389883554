import React, { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";

const NewInvoiceForm = ({
  handleSubmit,
  toggleNewInvoiceModal,
  countries,
  currencies,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [inputList, setInputList] = useState([
    {
      itemName: "",
      quantity: 1,
      unitPrice: 0,
    },
  ]);

  // handle input change
  const handleItemInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    console.log("Inputs", inputList);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        itemName: "",
        quantity: 1,
        unitPrice: 0,
      },
    ]);
  };

  const [invoiceDetails, setInvoiceDetails] = useState({
    customerName: "",
    customerEmail: "",
    currency: "NGN",
    country: "NIGERIA",
    service: "",
    preparedBy: {
      id: user?._id,
      name: `${user?.first_name} ${user?.last_name}`,
    },
    markup: 0,
    tax: 0,
    discount: 0,
    platform: "",
  });

  console.log(invoiceDetails);

  const [custNumber, setCustNumber] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInvoiceDetails({ ...invoiceDetails, [name]: value });
  };

  const submitForm = () => {
    handleSubmit({
      ...invoiceDetails,
      customerNumber: custNumber,
      items: inputList,
    });
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewInvoiceModal()}>
        <strong style={{ fontSize: "1.00rem" }}>ADD INVOICE</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="service">
                  <strong>Service</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="service"
                  name="service"
                  type="select"
                >
                  <option value="" disabled selected>
                    Select Service
                  </option>
                  <option value="pharmacy">PHARMACY</option>
                  <option value="lab">LAB</option>
                  <option value="consultation">CONSULTATION</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="platform">
                  <strong>Platform</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="platform"
                  name="platform"
                  type="select"
                >
                  <option value="" disabled selected>
                    Select Service
                  </option>
                  <option value="chatbot">Chatbot</option>
                  <option value="web">Web</option>
                  <option value="app">App</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="customerName">
                  <strong>Customer Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="customerName"
                  name="customerName"
                  placeholder="Customer Name"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="customerEmail">
                  <strong>Customer Email</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="customerEmail"
                  name="customerEmail"
                  placeholder="customerEmail@email.com"
                  type="email"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="customerNumber">
                  <strong>Customer Number</strong>
                </Label>
                <PhoneInput
                  id="customerNumber"
                  name="customerNumber"
                  className="form-control-alternative"
                  style={{
                    border: "none !important",
                    padding: "0.625rem 0.75rem",
                    borderRadius: "0.375rem",
                  }}
                  value=""
                  onChange={setCustNumber}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="Currency">
                  <strong>Currency</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="currency"
                  name="currency"
                  type="select"
                >
                  {currencies.map((currency) => (
                    <option value={currency.currency_code}>
                      {currency.currency_code}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="country">
                  <strong>Country</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="country"
                  name="country"
                  type="select"
                >
                  {countries.map((country) => (
                    <option value={country.country_name}>
                      {country.country_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {/* <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="markup">
                  <strong>Markup (%)</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="markup"
                  name="markup"
                  value={15}
                  type="number"
                  required
                  disabled
                />
              </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {/* <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="tax">
                  <strong>Tax (%)</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="tax"
                  name="tax"
                  placeholder="0"
                  type="number"
                  required
                  disabled
                />
              </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {/* <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="discount">
                  <strong>Discount (%)</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="discount"
                  name="discount"
                  placeholder="0"
                  type="number"
                  required
                  disabled
                />
              </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="preparedBy">
                  <strong>Prepared By</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="preparedBy"
                  name="preparedBy"
                  // value={user._id}
                  disabled
                  value={user?.first_name + " " + user?.last_name}
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="items">
                  <strong>Items</strong>
                </Label>

                {inputList.map((x, i) => {
                  return (
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Input
                            name="itemName"
                            className="form-control-alternative"
                            placeholder="Name"
                            value={x.item}
                            type="text"
                            onChange={(e) => handleItemInputChange(e, i)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Input
                            name="quantity"
                            className="form-control-alternative"
                            placeholder="Quantity"
                            type="number"
                            value={x.item}
                            onChange={(e) => handleItemInputChange(e, i)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Input
                            name="unitPrice"
                            className="form-control-alternative"
                            placeholder="Unit Price"
                            type="number"
                            value={x.item}
                            onChange={(e) => handleItemInputChange(e, i)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          {inputList.length !== 1 && (
                            <Button onClick={() => handleRemoveClick(i)}>
                              Remove
                            </Button>
                          )}
                          {inputList.length - 1 === i && (
                            <Button onClick={handleAddClick}>
                              Add Another Item
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  );
                })}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewInvoiceModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default NewInvoiceForm;
