import React, { useState } from "react";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

const NewAdminForm = ({ handleSubmit, toggleNewAdminModal }) => {
  const [adminDetails, setAdminDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    scope: "all",
    password: "",
    mobile_no: "",
    country_code: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAdminDetails({ ...adminDetails, [name]: value });
  };

  const submitForm = () => {
    handleSubmit(adminDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewAdminModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New Admin Account</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="first_name">
                  <strong>First Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="last_name">
                  <strong>Last Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="country_code">
                  <strong>Country Code</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="country_code"
                  name="country_code"
                  placeholder="+254"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="mobile_no">
                  <strong>Mobile Number</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="mobile_no"
                  name="mobile_no"
                  placeholder="71111111"
                  type="number"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="email">
                  <strong>Email</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="email"
                  name="email"
                  placeholder="email@email.com"
                  type="email"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="password">
                  <strong>Password</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="password"
                  name="password"
                  placeholder="password"
                  type="password"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_category_id">
                  <strong>Scope</strong>
                </Label>
                <Input
                  id="test_category_id"
                  name="test_category_id"
                  type="select"
                  className="form-control-alternative"
                  onChange={handleChange}
                  defaultValue="all"
                >
                  <option value="all">All</option>
                  <option value="doctors">Doctors</option>
                  <option value="patients">Patients</option>
                  <option value="pharmacy">Pharmacies</option>
                  <option value="labs">Labs</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewAdminModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default NewAdminForm;
