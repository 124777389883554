import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row,
  Container,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Col,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";
import SearchFilter from "components/SearchFilter/SearchFilter";

// import Pagination from "../Pagination/Pagination";
import NewPharmacyForm from "components/Pharmacies/modals/NewPharmacyForm";

import {
  fetchPharmacyList,
  updatePharmacyProfile,
  deletePharmacyAccount,
  createPharmacyAccount,
} from "store/slices/pharmacy";

const PharmacyList = (props) => {
  const [newPharmacyModalOpen, setNewPharmacyModalOpen] = useState(false);
  const data = useSelector((state) => state.pharmacies.pharmacyList);
  const loading = useSelector((state) => state.pharmacies.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPharmacyList());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.pharmacies);
    setUpdatedList(data.pharmacies);
  }, [data]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const toggleNewPharmacyModal = () => {
    setNewPharmacyModalOpen(!newPharmacyModalOpen);
  };

  const createNewPharmacy = (details) => {
    dispatch(createPharmacyAccount(details));
    toggleNewPharmacyModal();
  };

  const updateStatus = (pharmId, status) => {
    dispatch(
      updatePharmacyProfile({
        pharmId,
        status: status,
      })
    );
  };

  const deletePhamarcy = (pharmId) => {
    dispatch(deletePharmacyAccount(pharmId));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // const upd =
    setUpdatedList(
      filteredList.filter((pharmacy) => {
        const name = pharmacy.pharmacy_name.toLowerCase();
        const email = pharmacy.email.toLowerCase();
        const mobile = pharmacy.mobile_no.toLowerCase();
        const address = pharmacy.location.address.toLowerCase();
        const country = pharmacy.location.country.toLowerCase();
        const search = e.target.value.toLowerCase();
        return (
          name.includes(search) ||
          email.includes(search) ||
          mobile.includes(search) ||
          address.includes(search) ||
          country.includes(search)
        );
      })
    );
  };

  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader
              className="border-0"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h1
                className="mb-0"
                style={{
                  color: "#ea526f",
                }}
              >
                {data.pharmacies.length}{" "}
                <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                  PHARMACIES
                </span>
              </h1>
              <SearchFilter
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleChange={handleChange}
              />
              <Button
                color="default"
                type="button"
                className="float-right"
                onClick={() => setNewPharmacyModalOpen(true)}
              >
                + ADD PHARMACY
              </Button>

              <Modal
                fade={false}
                toggle={() => toggleNewPharmacyModal()}
                isOpen={newPharmacyModalOpen}
              >
                <NewPharmacyForm
                  handleSubmit={createNewPharmacy}
                  toggleNewPharmacyModal={toggleNewPharmacyModal}
                />
              </Modal>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>

                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CONTACT
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ADDRESS
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((pharm) => {
                    return (
                      <tr key={pharm._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt=""
                                src={
                                  pharm.profile_image.length
                                    ? pharm.profile_image
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0 text-sm"
                                data-test="pharm-name"
                              >
                                {pharm.pharmacy_name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{pharm.email}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {pharm.mobile_no}
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{pharm.location.country}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {pharm.location.address} {pharm.location.city}
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(pharm.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {pharm.status}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {pharm.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(pharm._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {pharm.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(pharm._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() => deletePhamarcy(pharm._id)}
                              >
                                Delete
                              </DropdownItem>

                              {/* <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {/* <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default PharmacyList;
