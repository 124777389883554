import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPharmacies,
  getPharmacyProfile,
  deletePharmacy,
  editPharmaciesProfile,
  fetchPharmacyOrders,
  fetchPharmacyOrdersWeb,
  updatePharmacyOrderStatus,
  fetchPharmacyProductsList,
  createPharmacyProduct,
  createCSVPharmarcyProduct,
  createPharmacy,
} from "../../services/api/pharmacies";

export const createPharmacyAccount = createAsyncThunk(
  "pharmacies/createPharmacy",
  async (params) => {
    const response = await createPharmacy(params);
    return response;
  }
);

export const fetchPharmacyList = createAsyncThunk(
  "pharmacies/fetchList",
  async (params) => {
    const response = await fetchPharmacies(params);
    return response;
  }
);

export const pharmacyProfile = createAsyncThunk(
  "pharmacies/pharmacyProfile",
  async (params) => {
    const response = await getPharmacyProfile(params);
    return response;
  }
);

export const updatePharmacyProfile = createAsyncThunk(
  "pharmacies/editProfile",
  async (params) => {
    const response = await editPharmaciesProfile(params);

    return response;
  }
);

export const deletePharmacyAccount = createAsyncThunk(
  "pharmacies/deletePharmacyProfile",
  async (params) => {
    const response = await deletePharmacy(params);
    return response;
  }
);

export const fetchPharmacyOrdersList = createAsyncThunk(
  "pharmacies/fetchOrdersList",
  async (params) => {
    const response = await fetchPharmacyOrders(params);
    return response;
  }
);
export const fetchPharmacyOrdersWebList = createAsyncThunk(
  "pharmacies/fetchOrdersWebList",
  async (params) => {
    const response = await fetchPharmacyOrdersWeb(params);
    return response;
  }
);

export const updateOrderStatus = createAsyncThunk(
  "pharmacies/updateOrderStatus",
  async (params) => {
    const response = await updatePharmacyOrderStatus(params);

    return response;
  }
);

export const fetchPharmacyProducts = createAsyncThunk(
  "pharmacies/fetchProductsList",
  async (params) => {
    const response = await fetchPharmacyProductsList(params);
    return response;
  }
);

export const createProduct = createAsyncThunk(
  "pharmacies/createProduct",
  async (params) => {
    const response = await createPharmacyProduct(params);
    return response;
  }
);

export const createCSVProduct = createAsyncThunk(
  "pharmacies/createACSVProduct",
  async (file) => {
    // console.log("file", file)
    // let formData = new FormData()
    // console.log("formData (Before)", formData)
    // formData.append("file",file)

    // console.log("formData (After)", formData)
    const response = await createCSVPharmarcyProduct(file);

    return response;
  }
);

export const pharmaciesSlice = createSlice({
  name: "pharmacies",
  initialState: {
    loading: false,
    pharmacyList: {
      pharmacies: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    pharmacyOrdersList: {
      orders: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    pharmacyOrdersWebList: {
      pharmacy_orders: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    pharmacyProductsList: {
      pharmacy_products: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(fetchPharmacyList.pending, (state, action) => {
    //   state.loading = true;
    // });

    builder.addCase(fetchPharmacyList.fulfilled, (state, action) => {
      state.pharmacyList = action.payload;
    });

    builder.addCase(fetchPharmacyOrdersList.fulfilled, (state, action) => {
      state.pharmacyOrdersList = action.payload;
    });
    builder.addCase(fetchPharmacyOrdersWebList.fulfilled, (state, action) => {
      state.pharmacyOrdersWebList = action.payload;
    });

    builder.addCase(fetchPharmacyProducts.fulfilled, (state, action) => {
      state.pharmacyProductsList = action.payload;
    });

    builder.addCase(updatePharmacyProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updatePharmacyProfile.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateOrderStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateOrderStatus.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deletePharmacyAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePharmacyAccount.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default pharmaciesSlice.reducer;
