import React, { useState } from "react";

import CurrenciesList from "components/Currencies/CurrencyList";

const Currencies = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD CURRENCY
      </Button> */}
      <CurrenciesList></CurrenciesList>
    </>
  );
};

export default Currencies;
