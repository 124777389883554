import React from "react";
// import { Button } from "reactstrap";

import DoctorsList from "components/Doctors/DoctorsList";

const Doctors = () => {
  return (
    <>
      <DoctorsList></DoctorsList>
    </>
  );
};

export default Doctors;
