import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { fetchTestCategoryList } from "store/slices/labs";
import { fetchLabsList } from "store/slices/labs";

const UpdateTestProductModal = ({
  handleSubmit,
  toggleUpdateTestProductModal,
  activeTestProduct,
}) => {
  const [testProductDetails, setTestProductDetails] = useState({
    name: activeTestProduct.name,
    test_category_id: activeTestProduct.category._id,
    description: activeTestProduct.description,
    understanding_test: activeTestProduct.understanding_test,
    price: activeTestProduct.price,
    test_center_id: activeTestProduct.test_center._id,
    home_test: activeTestProduct.home_test,
    available: activeTestProduct.available,
    discount: activeTestProduct?.discount || 0,
    image: activeTestProduct?.image?.imgPath,
  });

  const { test_centers } = useSelector((state) => state.labs.labsList);
  const { test_categories } = useSelector(
    (state) => state.labs.testCategoryList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsList());
    dispatch(fetchTestCategoryList());
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTestProductDetails({
      ...testProductDetails,
      [name]: value,
    });
  };

  const handleChekbox = (event) => {
    setTestProductDetails({
      ...testProductDetails,
      home_test: event.target.checked,
    });
  };

  const submitForm = () => {
    const payload = {
      id: activeTestProduct._id,
      payload: {
        ...testProductDetails,
        image: {
          imgPath: testProductDetails.image,
        },
      },
    };
    handleSubmit(payload);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleUpdateTestProductModal()}>
        <strong style={{ fontSize: "1.00rem" }}>Update Test Product</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_center_id">
                  <strong>Test Centre</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="test_center_id"
                  name="test_center_id"
                  type="select"
                  className="form-control-alternative"
                  value={testProductDetails.test_center_id || ""}
                >
                  <option defaultValue="" disabled>
                    Select Test Centre
                  </option>
                  {test_centers &&
                    test_centers.map((lab) => (
                      <option
                        defaultValue={lab._id}
                        key={lab._id}
                        value={lab._id}
                      >
                        {lab.center_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_center_id">
                  <strong>Test Category</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="test_category_id"
                  name="test_category_id"
                  type="select"
                  className="form-control-alternative"
                  value={testProductDetails.test_category_id || ""}
                >
                  <option defaultValue="" disabled>
                    Select Test Category
                  </option>
                  {test_categories &&
                    test_categories.map((testCenter) => (
                      <option
                        defaultValue={testCenter._id}
                        key={testCenter._id}
                        value={testCenter._id}
                      >
                        {testCenter.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Test Product Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder="Test Product Name"
                  type="text"
                  value={testProductDetails.name || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Price</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="price"
                  placeholder="Price"
                  name="price"
                  type="text"
                  value={testProductDetails.price || ""}
                  //   min="1"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="description"
                  name="description"
                  type="textarea"
                  placeholder="Product Description..."
                  className="form-control-alternative"
                  value={testProductDetails.description || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Understanding Test</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="understanding_test"
                  name="understanding_test"
                  type="textarea"
                  placeholder="Understanding Test"
                  className="form-control-alternative"
                  value={testProductDetails.understanding_test || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Display Image</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="image"
                  name="image"
                  type="textarea"
                  placeholder="Product Image"
                  className="form-control-alternative"
                  value={testProductDetails.image || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="discount">
                  <strong>Discount</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="discount"
                  placeholder="Discount"
                  name="discount"
                  type="text"
                  value={testProductDetails?.discount || "0"}
                  //   max="100"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="my-2">
              <FormGroup check>
                <Input
                  type="checkbox"
                  onChange={handleChekbox}
                  id="home_test"
                  name="home_test"
                  className="form-control-alternative"
                  checked={testProductDetails.available || false}
                />
                <Label check style={{ fontSize: "0.85rem" }} for="home_test">
                  <strong>Is Test Product currently available ?</strong>
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="my-2">
              <FormGroup check>
                <Input
                  type="checkbox"
                  onChange={handleChekbox}
                  id="home_test"
                  name="home_test"
                  className="form-control-alternative"
                  checked={testProductDetails.home_test || false}
                />
                <Label check style={{ fontSize: "0.85rem" }} for="home_test">
                  <strong>Is Home Test available for this test ?</strong>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={submitForm}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleUpdateTestProductModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default UpdateTestProductModal;
