import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchAdmins } from "services/api/admins";
import { signupAdmin } from "services/api/auth";

export const fetchAdminsList = createAsyncThunk(
  "admins/fetchList",
  async (params) => {
    const response = await fetchAdmins(params);
    return response;
  }
);

export const createAdminAccount = createAsyncThunk(
  "admins/signupAdmin",
  async (params) => {
    const response = await signupAdmin(params);
    return response;
  }
);

export const currenciesSlice = createSlice({
  name: "admins",
  initialState: {
    loading: false,
    adminsList: {
      accounts: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminsList.fulfilled, (state, action) => {
      state.adminsList = action.payload;
    });
  },
});

export default currenciesSlice.reducer;
