import React from "react";
// import { Button } from "reactstrap";

import CountryList from "components/Countries/CountriesList";

const Countries = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD COUNTRY
      </Button> */}
      <CountryList></CountryList>
    </>
  );
};

export default Countries;
