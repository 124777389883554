import React from "react";
// import { Button } from "reactstrap";

import LanguageList from "components/Languages/LanguageList";

const Languages = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD Language
      </Button> */}
      <LanguageList></LanguageList>
    </>
  );
};

export default Languages;
