import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchPrescriptions } from "services/api/prescriptions";

export const fetchPrescriptionsList = createAsyncThunk(
  "admins/fetchPrescriptionsList",
  async (params) => {
    const response = await fetchPrescriptions(params);
    return response;
  }
);

export const updateResolutionStatus = createAsyncThunk(
  "pharmacies/updateOrderStatus",
  async (params) => {
    const response = await updatePrescriptionResolutionStatus(params);

    return response;
  }
);

export const prescriptionSlice = createSlice({
  name: "prescriptions",
  initialState: {
    loading: false,
    prescriptionList: {
      prescriptions: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrescriptionsList.fulfilled, (state, action) => {
      state.prescriptionList = action.payload;
    });
  },
});

export default prescriptionSlice.reducer;
