import React, { useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

const UploadCSVForm = (props) => {
  const { handleSubmit, toggleNewCSVModal } = props;
  const [csvData, setCsvData] = useState(null);

  const handleFileChange = (e) => {
    const fileList = e.target.files;
    console.log("e.target", e.target.files);
    console.log("fileList", fileList);
    if (fileList && e.target.files.length > 0) {
      setCsvData({
        pharmacy_product_csv: fileList[0],
      });
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => {
      const csvDataRes = reader.result;
      setCsvData(csvDataRes);
    };
    setCsvData(acceptedFiles[0]);
  }, []);

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const submitForm = (e) => {
    if (e) {
      e.preventDefault();
    }
    let formData = new FormData();
    // console.log("csvData", csvData)
    // formData.append('file', csvData)
    // console.log("formData", formData)
    handleSubmit(csvData);
  };
  return (
    <>
      <ModalHeader>
        <strong style={{ fontSize: "1.00rem" }}>CSV Upload</strong>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitForm}>
          <FormGroup>
            <Label>Drag and drop a file here, or click to select a file</Label>
            <div
              className="border border-dashed border-gray-400 rounded-lg p-6 mt-2 cursor-pointer"
              {...getRootProps()}
            >
              <input {...getInputProps()} ref={inputRef} accept=".csv" />
              {csvData ? (
                <p>{csvData.name}</p>
              ) : (
                <input type="file" onChange={handleFileChange} />
              )}
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => submitForm()}
          disabled={!csvData}
        >
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewCSVModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default UploadCSVForm;
