import { format } from "date-fns";

export function formatDate(timestamp) {
  if (!timestamp) return "--";
  try {
    const date = new Date(timestamp);
    return format(date, "dd/MM/yyyy - HH:mm");
  } catch (error) {
    return "--";
  }
}
