import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TextArea,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { fetchLanguagesList } from "store/slices/languages";

const NewLanguageForm = ({ handleSubmit, toggleNewLanguageModal }) => {
  const [languageDetails, setLanguageDetails] = useState({
    language_name: "",
    language_code: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLanguageDetails({ ...languageDetails, [name]: value });
    console.log(languageDetails);
  };
  const { languages } = useSelector((state) => state.languages.languagesList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLanguagesList());
  }, [dispatch]);

  const submitForm = () => {
    handleSubmit(languageDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewLanguageModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New Language</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Language Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="language_name"
                  name="language_name"
                  placeholder="Language Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Language Code</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="language_code"
                  name="language_code"
                  placeholder="Language Code"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewLanguageModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};
export default NewLanguageForm;
