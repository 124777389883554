import client from "../axios";

export async function fetchAppointments(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/appointment/list_appointments?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("[Appointments Fetch Error]:", error);
    throw new Error("An error occured while fetching appointments");
  }
}
