import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { loginAdmin } from "services/api/auth";

export const login = createAsyncThunk("auth/logIn", async (params) => {
  const { user, token } = await loginAdmin(params);

  if (token) {
    localStorage.setItem("access_token", token);
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    throw new Error("Could not verify session token");
  }

  return user;
});

export const logout = createAsyncThunk("auth/logOut", async (params) => {
  // await logoutAdmin(params);

  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.user = {};
      state.loggedIn = false;
    });
  },
});

export default authSlice.reducer;
