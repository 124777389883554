import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./slices/auth";
import adminsReducer from "./slices/admins";
import toastReducer from "./slices/toast";
import doctorsReducer from "./slices/doctors";
import patientsReducer from "./slices/patients";
import pharmaciesReducer from "./slices/pharmacy";
import prescriptionsReducer from "./slices/prescriptions";
import labsReducer from "./slices/labs";
import advertsReducer from "./slices/advertisements";
import currenciesReducer from "./slices/currency";
import citiesReducer from "./slices/cities";
import countriesReducer from "./slices/countries";
import languagesReducer from "./slices/languages";
import testCategoriesReducer from "./slices/testCategories";
import pharmacyProductCategoriesReducer from "./slices/pharmacyProductCategories";
import appointmentsReducer from "./slices/appointments";
import invoicesReducer from "./slices/invoices";
import reportsReducer from "./slices/reports";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export default configureStore({
  reducer: {
    auth: authReducer,
    admins: adminsReducer,
    doctors: doctorsReducer,
    patients: patientsReducer,
    pharmacies: pharmaciesReducer,
    prescriptions: prescriptionsReducer,
    labs: labsReducer,
    invoices: invoicesReducer,
    adverts: advertsReducer,
    currencies: currenciesReducer,
    cities: citiesReducer,
    countries: countriesReducer,
    languages: languagesReducer,
    testCategories: testCategoriesReducer,
    pharmacyProductCategories: pharmacyProductCategoriesReducer,
    appointments: appointmentsReducer,
    reports: reportsReducer,
  },
  middleware: customizedMiddleware,
});
