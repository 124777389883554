import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TextArea,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from "reactstrap";

import { fetchLabsList, fetchPharmacyList } from "store/slices/labs";
import { fetchCategoryList } from "store/slices/testCategories";

const NewProductForm = ({ handleSubmit, toggleNewProductModal }) => {
  const [productDetails, setProductDetails] = useState({
    name: "",
    test_center_id: "",
    test_category_id: "",
    description: "",
    understanding_test: "",
    price: 0,
    home_test: false,
  });

  const { test_centers } = useSelector((state) => state.labs.labsList);
  const { test_categories } = useSelector(
    (state) => state.testCategories.categoriesList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsList());
    dispatch(fetchCategoryList());
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setProductDetails({
      ...productDetails,
      [name]: name === "home_test" ? checked : value,
    });
  };

  const submitForm = () => {
    handleSubmit(productDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewProductModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New Lab Product</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_category_id">
                  <strong>Product Category</strong>
                </Label>
                <Input
                  id="test_category_id"
                  name="test_category_id"
                  type="select"
                  className="form-control-alternative"
                  onChange={handleChange}
                >
                  <option defaultValue="" disabled selected>
                    Select Product Category
                  </option>
                  {test_categories &&
                    test_categories.map((category) => (
                      <option
                        defaultValue={
                          // category._id
                          "5f74342cde37b520a5b535dc"
                        }
                        key={category._id}
                      >
                        {category.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_center_id">
                  <strong>Lab</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="test_center_id"
                  name="test_center_id"
                  type="select"
                  className="form-control-alternative"
                >
                  <option defaultValue="" disabled selected>
                    Select Lab
                  </option>
                  {test_centers &&
                    test_centers.map((lab) => (
                      <option defaultValue={lab._id} key={lab._id}>
                        {lab.center_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Product Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder="Product Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Price</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="price"
                  placeholder="Price"
                  name="price"
                  type="number"
                  min="1"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="description"
                  name="description"
                  type="textarea"
                  placeholder="Product Description..."
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="understanding_test">
                  <strong>Understanding Test</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="understanding_test"
                  name="understanding_test"
                  type="textarea"
                  placeholder="Describe test results..."
                  className="form-control-alternative"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="my-2">
              <FormGroup check>
                <Input
                  type="checkbox"
                  onChange={handleChange}
                  id="home_test"
                  name="home_test"
                  className="form-control-alternative"
                />
                <Label check style={{ fontSize: "0.85rem" }} for="home_test">
                  <strong>Can Samples Be Collected At Home?</strong>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewProductModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default NewProductForm;
