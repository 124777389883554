import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from "reactstrap";

import { fetchCategoryList } from "store/slices/pharmacyProductCategories";
import client from "../../../services/axios";

const NewPharmCategoryForm = ({
  handleSubmit,
  toggleNewPharmCategoryModal,
}) => {
  const [pharmSubCategoryDetails, setPharmSubCategoryDetails] = useState({
    name: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPharmSubCategoryDetails({ ...pharmSubCategoryDetails, [name]: value });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategoryList());
  }, [dispatch]);

  const submitForm = async () => {
    try {
      const { data } = await client.post(
        "/pharmacy_sub_category/sub_category",
        pharmSubCategoryDetails
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewPharmCategoryModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New SubCategory</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>SubCategory Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder="SubCategory Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="description"
                  name="description"
                  placeholder="Description"
                  type="textarea"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewPharmCategoryModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};
export default NewPharmCategoryForm;
