import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

// import Pagination from "../Pagination/Pagination";
import NewLanguageForm from "components/Languages/modals/NewLanguageForm";
import {
  fetchLanguagesList,
  updateLanguageStatus,
  // deleteLanguageAccount,
  createNewLanguage,
} from "store/slices/languages";
import { formatDate } from "utils/date";

const LanguagesList = (props) => {
  const [newLanguageModalOpen, setNewLanguageModalOpen] = useState(false);
  const data = useSelector((state) => state.languages.languagesList);
  const loading = useSelector((state) => state.languages.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLanguagesList());
  }, [dispatch, loading]);

  //   const getBadgeColor = (status) => {
  //     switch (status) {
  //       case "active":
  //         return "badge-success";
  //       case "pending":
  //         return "badge-warning";
  //       case "deactivated":
  //         return "badge-dark";
  //       default:
  //         break;
  //     }
  // };

  const toggleNewLanguageModal = () => {
    setNewLanguageModalOpen(!newLanguageModalOpen);
  };

  const getPage = (index) => {
    dispatch(
      fetchLanguagesList({
        page: index,
      })
    );
  };

  const updateStatus = (languageId, status) => {
    dispatch(
      updateLanguageStatus({
        languageId,
        status: status,
      })
    );
  };

  const deleteLanguageAccount = (languageId) => {
    dispatch(deleteLanguageAccount(languageId));
  };

  const createLanguage = (details) => {
    dispatch(createNewLanguage(details));
    toggleNewLanguageModal();
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.languages.length}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        LANGUAGES
                      </span>
                    </h1>
                  </div>
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewLanguageModalOpen(true)}
                    >
                      + ADD NEW LANGUAGE
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewLanguageModal()}
                      isOpen={newLanguageModalOpen}
                    >
                      <NewLanguageForm
                        handleSubmit={createLanguage}
                        toggleNewLanguageModal={toggleNewLanguageModal}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      NAME
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CODE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CREATED ON
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.languages.map((language) => {
                    return (
                      <tr key={language._id}>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{language.language_name}</strong>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>
                              {language.language_code.toUpperCase()}
                            </strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            {formatDate(language.createdAt)}
                          </div>
                        </td>

                        {/* <td className="text-left">
                          <Badge
                            className={getBadgeColor(language.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {language.status}
                          </Badge>
                        </td> */}
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LanguagesList;
