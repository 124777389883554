import client from "../axios.js";

export async function fetchCurrencies(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(`/currency?page=${params.page}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("[CURRENCIES FETCH ERR]:", error);
    throw new Error("An error occured while fetching currencies");
  }
}

export async function createCurrency(currency) {
  try {
    const response = await client.post(
      "/currency/",
      currency,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("CURRENCY CREATION ERR]:", error);
    throw new Error("An error occured while creating a currency");
  }
}
