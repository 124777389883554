import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  // Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  Table,
  Row,
  // UncontrolledTooltip,
} from "reactstrap";

// import Pagination from "../Pagination/Pagination";
import OrderDetails from "./Modals/OrderDetailsModal";

import { fetchLabsOrdersList, updateOrderStatus } from "store/slices/labs";
import SearchFilter from "components/SearchFilter/SearchFilter";

import { formatDate } from "utils/date";

const LabsOrdersList = (props) => {
  const [orderDetailsModalOpen, setOrderDetailsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(false);
  const data = useSelector((state) => state.labs.labsOrdersList);
  const loading = useSelector((state) => state.labs.loading);
  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(0);
  console.log(data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsOrdersList());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.orders);
    setUpdatedList(data.orders);
  }, [data]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "delivered":
        return "badge-success";
      case "confirmed":
        return "badge-info";
      case "pending":
        return "badge-warning";
      case "canceled":
      case "rejected":
        return "badge-danger";
      default:
        break;
    }
  };

  const getPaymentBadgeColor = (status) => {
    switch (status) {
      case "completed":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "canceled":
      case "failed":
        return "badge-danger";
      default:
        break;
    }
  };

  /* const getPage = (index) => {
    dispatch(
      fetchLabsOrdersList({
        page: index,
      })
    );
  }; */

  const updateStatus = (orderId, status) => {
    dispatch(
      updateOrderStatus({
        orderId,
        status,
      })
    );
  };

  const viewOrder = (order) => {
    setSelectedOrder(order);
    setOrderDetailsModalOpen(true);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // const upd =
    setUpdatedList(
      filteredList.filter((order) => {
        const orderId = order._id.toLowerCase();
        const userName = order.user_info.name.toLowerCase();
        const email = order.user_info.email.toLowerCase();
        const mobile = order.user_info.mobile_no.toLowerCase();

        const search = e.target.value.toLowerCase();
        return (
          orderId.includes(search) ||
          userName.includes(search) ||
          email.includes(search) ||
          mobile.includes(search)
        );
      })
    );
  };

  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0" style={{ display: "flex" }}>
              <h1 className="mb-0" style={{ color: "#ea526f" }}>
                {updatedList.length}{" "}
                <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                  LABS ORDERS
                </span>
              </h1>
              <div style={{ textAlign: "center", margin: "0 auto" }}>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
              </div>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ORDER ID
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CONTACT
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ADDRESS
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    LAB
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PLACED ON
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PRICE
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PAYMENT STATUS
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ORDER STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((order) => {
                    const { user_info, delivery_address } = order;

                    return (
                      <tr key={order._id}>
                        <td>
                          <span
                            className="mb-0 text-sm"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => viewOrder(order)}
                          >
                            {order._id.toUpperCase().slice(-8)}
                          </span>
                        </td>
                        <th scope="row">
                          {!user_info ? (
                            <span className="mb-0 text-sm text-light">
                              Deleted Acccount
                            </span>
                          ) : (
                            <span
                              className="mb-0 text-sm"
                              style={{ textTransform: "capitalize" }}
                            >
                              {user_info?.name}
                            </span>
                          )}
                        </th>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{user_info?.email}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {user_info?.mobile_no}
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{delivery_address?.address_title}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {delivery_address?.address} -{" "}
                            {delivery_address?.landmark}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.90rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{order.test_center_id?.center_name}</strong>
                          </div>
                        </td>
                        <td>
                          <span>{formatDate(order.createdAt)}</span>
                        </td>
                        <td>
                          <span>
                            {order.payment?.currency} {order.payment?.amount}
                          </span>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getPaymentBadgeColor(
                              order.payment?.status
                            )}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {order.payment?.status}
                          </Badge>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(order.order_status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {order.order_status}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {order.order_status !== "delivered" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(order._id, "delivered")
                                  }
                                >
                                  Fulfill
                                </DropdownItem>
                              )}
                              {order.order_status !== "rejected" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(order._id, "rejected")
                                  }
                                >
                                  Reject
                                </DropdownItem>
                              )}
                              {order.order_status !== "pending" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(order._id, "pending")
                                  }
                                >
                                  Set As Pending
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {orderDetailsModalOpen && (
              <Modal
                fade={false}
                toggle={() => setOrderDetailsModalOpen(!orderDetailsModalOpen)}
                isOpen={orderDetailsModalOpen}
              >
                <OrderDetails
                  order={selectedOrder}
                  close={() => {
                    setOrderDetailsModalOpen(false);
                  }}
                  getStatusBadgeColor={getBadgeColor}
                  getPaymentBadgeColor={getPaymentBadgeColor}
                />
              </Modal>
            )}
            <CardFooter className="py-4">
              {/* <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default LabsOrdersList;
