import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row,
  Container,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Col,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

import Pagination from "components/Pagination/Pagination";

import SearchFilter from "components/SearchFilter/SearchFilter";
import { fetchTestKits, editTestKit, removeTestKit } from "store/slices/labs";
import NewTestKitModal from "./Modals/NewTestKitModal";
import { addTestKit } from "store/slices/labs";

const TestKitList = (props) => {
  const [newTestkitModalOpen, setNewTestkitModalOpen] = useState(false);
  const data = useSelector((state) => state.labs.labsTestKitList);
  const loading = useSelector((state) => state.labs.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTestKits());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.testKits);
    setUpdatedList(data.testKits);
  }, [data.testKits]);

  const toggleNewTestkitModal = () => {
    setNewTestkitModalOpen((prev) => !prev);
  };

  const getPage = (index) => {
    dispatch(
      fetchTestKits({
        page: index,
      })
    );
  };

  const createNewProduct = (details) => {
    dispatch(addTestKit(details));
    // toggleNewTestkitModal();
  };

  const updateRecord = (id, data) => {
    dispatch(editTestKit({ id, data }));
  };

  const deleteRecord = async (reportId) => {
    await dispatch(removeTestKit(reportId));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    setUpdatedList(
      filteredList.filter((product) => {
        const price = product?.price;
        const name = product?.name.toLowerCase();
        const centerName = product?.test_center[0]?.center_name.toLowerCase();
        const category = product?.test_category[0]?.name.toLowerCase();

        const search = e.target.value.toLowerCase();
        return (
          `${price}`.includes(search) ||
          name?.includes(search) ||
          centerName?.includes(search) ||
          category?.includes(search)
        );
      })
    );
  };

  return (
    <>
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col">
                  <h1 className="mb-0" style={{ color: "#ea526f" }}>
                    {updatedList.length}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      TEST KITS
                    </span>
                  </h1>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <div className="col">
                  <Button
                    color="default"
                    type="button"
                    className="float-right"
                    onClick={() => setNewTestkitModalOpen(true)}
                  >
                    + ADD TEST KIT
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewTestkitModal()}
                    isOpen={newTestkitModalOpen}
                  >
                    <NewTestKitModal
                      handleSubmit={createNewProduct}
                      toggleNewTestKitModal={toggleNewTestkitModal}
                    />
                  </Modal>
                </div>
                {/* <div className="col">Add</div> */}
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    TEST CENTER
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CATEGORY
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DESCRIPTION
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PRICE
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((product) => {
                    return (
                      <tr key={product._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="test img"
                                src={
                                  product?.image
                                    ? product.image.imgPath
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0"
                                data-test="test_product-name"
                                style={{
                                  fontSize: "0.85rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                <strong>{product.name}</strong>
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>
                              {product?.test_center[0]?.center_name}
                            </strong>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{product?.test_category[0]?.name}</strong>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{product?.description}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product.price}</strong>
                          </div>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => updateRecord(product._id, {})}
                              >
                                Update
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => deleteRecord(product._id)}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default TestKitList;
