import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchCurrencies, createCurrency } from "../../services/api/currency";

export const fetchCurrenciesList = createAsyncThunk(
  "currencies/fetchList",
  async (params) => {
    const response = await fetchCurrencies(params);
    return response;
  }
);

export const createNewCurrency = createAsyncThunk(
  "currencies/createCurrency",
  async (params) => {
    const response = await createCurrency(params);
    return response;
  }
);

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState: {
    loading: false,
    currenciesList: {
      currencies: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrenciesList.fulfilled, (state, action) => {
      state.currenciesList = action.payload;
    });
  },
});

export default currenciesSlice.reducer;
