import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

// import Pagination from "../Pagination/Pagination";

import NewCountryForm from "components/Countries/modals/NewCountryForm";
import { formatDate } from "utils/date";
import {
  fetchCountriesList,
  updateCountryStatus,
  // deleteCountryAccount,
  createNewCountry,
} from "store/slices/countries";

const CountryList = (props) => {
  const [newCountryModalOpen, setNewCountryModalOpen] = useState(false);
  const data = useSelector((state) => state.countries.countriesList);
  const loading = useSelector((state) => state.countries.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountriesList());
  }, [dispatch, loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const toggleNewCountryModal = () => {
    setNewCountryModalOpen(!newCountryModalOpen);
  };

  const getPage = (index) => {
    dispatch(
      fetchCountriesList({
        page: index,
      })
    );
  };

  const updateStatus = (countryId, status) => {
    dispatch(
      updateCountryStatus({
        countryId,
        status: status,
      })
    );
  };

  const deleteCountryAccount = (countryId) => {
    dispatch(deleteCountryAccount(countryId));
  };

  const createCountry = (details) => {
    dispatch(createNewCountry(details));
    toggleNewCountryModal();
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.countries.length}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        COUNTRIES
                      </span>
                    </h1>
                  </div>
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewCountryModalOpen(true)}
                    >
                      + ADD NEW COUNTRY
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewCountryModal()}
                      isOpen={newCountryModalOpen}
                    >
                      <NewCountryForm
                        handleSubmit={createCountry}
                        toggleNewCountryModal={toggleNewCountryModal}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      NAME
                    </th>

                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CODE
                    </th>

                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      FLAG
                    </th>

                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CURRENCY
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CREATED ON
                    </th>

                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.countries.map((country) => {
                    return (
                      <tr key={country._id}>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{country.country_name}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{country.country_code}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{country.country_flag}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{country.currency.currency_code}</strong>
                          </div>
                          <span style={{ fontSize: "0.85rem" }}>
                            {formatDate(country.createdAt)}
                          </span>
                        </td>

                        {/* <td className="text-left">
                          <Badge
                            className={getBadgeColor(country.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {country.status}
                          </Badge>
                        </td> */}
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            {/* <DropdownMenu className="dropdown-menu-arrow" right>
                              {country.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(country._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {country.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(country._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() =>
                                  deleteCountryAccount(country._id)
                                }
                              >
                                Delete
                              </DropdownItem> */}

                            {/* <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem> */}
                            {/* </DropdownMenu> */}
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CountryList;
