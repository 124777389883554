import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  // Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Media,
  Modal,
  Table,
  Row,
  // UncontrolledTooltip,
} from "reactstrap";
import { formatDate } from "utils/date";
import OrderDetails from "./modals/OrderDetailsModal";

import {
  fetchPharmacyOrdersWebList,
  updateOrderStatus,
} from "store/slices/pharmacy";

const PharmacyOrdersWebList = (props) => {
  const [orderDetailsModalOpen, setOrderDetailsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(false);
  const data = useSelector((state) => state.pharmacies.pharmacyOrdersWebList);
  const loading = useSelector((state) => state.pharmacies.loading);

  console.log("data", data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPharmacyOrdersWebList());
  }, [dispatch, loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "delivered":
        return "badge-success";
      case "confirmed":
        return "badge-info";
      case "pending":
        return "badge-warning";
      case "canceled":
      case "rejected":
        return "badge-danger";
      default:
        break;
    }
  };

  const getPaymentBadgeColor = (status) => {
    switch (status) {
      case "completed":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "canceled":
      case "failed":
        return "badge-danger";
      default:
        break;
    }
  };

  const updateStatus = (orderId, status) => {
    dispatch(
      updateOrderStatus({
        orderId,
        status,
      })
    );
  };

  const viewOrder = (order) => {
    setSelectedOrder(order);
    setOrderDetailsModalOpen(true);
  };

  return (
    <>
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader>
              <h1 className="mb-0" style={{ color: "#ea526f" }}>
                {data.pharmacy_orders.length}{" "}
                <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                  PHARMACY WEB ORDERS
                </span>
              </h1>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ORDER ID
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CONTACT
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ADDRESS
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PAYMENT TYPE
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PLACED ON
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ORDER STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {data.pharmacy_orders.map((order) => {
                  const { _id, delivery_address } = order;

                  return (
                    <tr key={order._id}>
                      <td>
                        <span
                          className="mb-0 text-sm"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => viewOrder(order)}
                        >
                          {order._id.toUpperCase().slice(-8)}
                        </span>
                      </td>
                      <td scope="row">
                        <span className="mb-0 text-sm">{order.name}</span>
                      </td>
                      <td>
                        <div style={{ fontSize: "0.90rem" }}>
                          <strong>{order?.email}</strong>
                        </div>
                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {order?.phone}
                        </div>
                      </td>
                      <td>
                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {delivery_address?.address} -{" "}
                          {delivery_address?.hseNumber}
                          {delivery_address?.city}
                        </div>
                      </td>
                      <td>
                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {order?.payment_type}
                        </div>
                      </td>
                      <td>
                        <span>{formatDate(order.createdAt)}</span>
                      </td>
                      <td className="text-left">
                        <Badge
                          className={getBadgeColor(order?.order_status)}
                          pill
                          style={{
                            fontSize: "0.77rem",
                            textTransform: "capitalize",
                          }}
                        >
                          {order?.order_status}
                        </Badge>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={() => viewOrder(order)}>
                              View More Details
                            </DropdownItem>
                            {order.order_status !== "pending" && (
                              <DropdownItem
                                onClick={() =>
                                  updateStatus(order._id, "pending")
                                }
                              >
                                confirmed
                              </DropdownItem>
                            )}
                            {order.order_status !== "confirmed" && (
                              <DropdownItem
                                onClick={() =>
                                  updateStatus(order._id, "confirmed")
                                }
                              >
                                pending
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {orderDetailsModalOpen && (
              <Modal
                fade={false}
                toggle={() => setOrderDetailsModalOpen(!orderDetailsModalOpen)}
                isOpen={orderDetailsModalOpen}
              >
                <OrderDetails
                  order={selectedOrder}
                  close={() => {
                    setOrderDetailsModalOpen(false);
                  }}
                  getStatusBadgeColor={getBadgeColor}
                  getPaymentBadgeColor={getPaymentBadgeColor}
                />
              </Modal>
            )}
            <CardFooter className="py-4">
              {/* <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default PharmacyOrdersWebList;
