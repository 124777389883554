import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row,
  Container,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Col,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";
import NewLabForm from "components/Labs/Modals/AddLabModal";

// import Pagination from "../Pagination/Pagination";
import {
  fetchLabsList,
  updateLabProfile,
  deleteLabAccount,
  createLabAccount,
} from "store/slices/labs";
import SearchFilter from "components/SearchFilter/SearchFilter";

const LabsList = (props) => {
  const [newLabModalOpen, setNewLabModalOpen] = useState(false);
  const data = useSelector((state) => state.labs.labsList);
  const loading = useSelector((state) => state.labs.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsList());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.test_centers);
    setUpdatedList(data.test_centers);
  }, [data]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const getPage = (index) => {
    dispatch(
      fetchLabsList({
        page: index,
      })
    );
  };
  const toggleNewLabModal = () => {
    setNewLabModalOpen(!newLabModalOpen);
  };

  const createNewLab = (details) => {
    dispatch(createLabAccount(details));
    toggleNewLabModal();
  };

  const updateStatus = (labId, status) => {
    dispatch(
      updateLabProfile({
        labId,
        status: status,
      })
    );
  };

  const deleteLab = (labId) => {
    dispatch(deleteLabAccount(labId));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // const upd =
    setUpdatedList(
      filteredList.filter((lab) => {
        const labName = lab.center_name.toLowerCase();
        const labEmail = lab.email.toLowerCase();
        const country = lab.location.country.toLowerCase();
        const address = lab.location.address.toLowerCase();
        const city = lab.location.city.toLowerCase();
        const mobile = lab.mobile_no.toLowerCase();
        const search = searchTerm.toLowerCase();
        return (
          labName.includes(search) ||
          labEmail.includes(search) ||
          country.includes(search) ||
          address.includes(search) ||
          city.includes(search) ||
          mobile.includes(search)
        );
      })
    );
  };

  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1
                className="mb-0"
                style={{
                  color: "#ea526f",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {data.test_centers.length}{" "}
                  <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                    LABS
                  </span>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <Button
                  color="default"
                  type="button"
                  className="float-right"
                  onClick={() => setNewLabModalOpen(true)}
                >
                  + ADD LAB
                </Button>
              </h1>
              <Modal
                fade={false}
                toggle={() => toggleNewLabModal()}
                isOpen={newLabModalOpen}
              >
                <NewLabForm
                  handleSubmit={createNewLab}
                  toggleNewLabModal={toggleNewLabModal}
                />
              </Modal>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>

                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CONTACT
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ADDRESS
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((lab) => {
                    return (
                      <tr key={lab._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="lab's profile img"
                                src={
                                  lab.profile_image.length
                                    ? lab.profile_image
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0 text-sm"
                                data-test="lab-name"
                              >
                                {lab.center_name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{lab.email}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {lab.mobile_no}
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{lab.location.country}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {lab.location.address} {lab.location.city}
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(lab.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {lab.status}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {lab.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(lab._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {lab.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(lab._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem onClick={() => deleteLab(lab._id)}>
                                Delete
                              </DropdownItem>

                              {/* <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {/* <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default LabsList;
