import React from "react";
// import { Button } from "reactstrap";

import InvoicesList from "components/Payments/InvoicesList";

const Invoices = () => {
  return (
    <>
      <InvoicesList></InvoicesList>
    </>
  );
};

export default Invoices;
