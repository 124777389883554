import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchLanguages,
  editLanguage,
  deleteLanguage,
  createLanguage,
} from "../../services/api/languages";

export const fetchLanguagesList = createAsyncThunk(
  "languages/fetchList",
  async (params) => {
    const response = await fetchLanguages(params);
    return response;
  }
);

export const updateLanguageStatus = createAsyncThunk(
  "languages/updateStatus",
  async (params) => {
    const response = await editLanguage(params);

    return response;
  }
);

export const deleteLanguageAccount = createAsyncThunk(
  "languages/deleteLanguage",
  async (params) => {
    const response = await deleteLanguage(params);
    return response;
  }
);
export const createNewLanguage = createAsyncThunk(
  "languages/createLanguage",
  async (params) => {
    const response = await createLanguage(params);
    return response;
  }
);
export const citiesSlice = createSlice({
  name: "languages",
  initialState: {
    loading: false,
    languagesList: {
      languages: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLanguagesList.fulfilled, (state, action) => {
      state.languagesList = action.payload;
    });
    builder.addCase(updateLanguageStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateLanguageStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLanguageAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLanguageAccount.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default citiesSlice.reducer;
