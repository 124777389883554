import client from "services/axios";

export async function signupAdmin(admin) {
  try {
    const response = await client.post("/auth/admin_signup", admin, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("ADMIN CREATION ERR]:", error);
    throw new Error("An error occured while signing up an admin");
  }
}

export async function loginAdmin(details) {
  console.log("DEET", details);

  try {
    const response = await client.post("/auth/admin_login", details, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);

    return response.data;
  } catch (error) {
    console.log("ADMIN LOGIN ERR]:", error);
    throw new Error("An error occured while logging in");
  }
}
