import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchAdverts } from "../../services/api/advertisements";

export const fetchAdvertsList = createAsyncThunk(
  "adverts/fetchList",
  async (params) => {
    const response = await fetchAdverts(params);
    return response;
  }
);

export const advertsSlice = createSlice({
  name: "adverts",
  initialState: {
    loading: false,
    advertsList: {
      adverts: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdvertsList.fulfilled, (state, action) => {
      state.advertsList = action.payload;
    });
  },
});

export default advertsSlice.reducer;
