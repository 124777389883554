import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Container,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Button,
  Row,
  // UncontrolledTooltip,
} from "reactstrap";

// import Pagination from "../Pagination/Pagination";
import { fetchAppointmentsList } from "store/slices/appointments";
import { formatDate } from "utils/date";

const AppointmentsList = (props) => {
  const data = useSelector((state) => state.appointments.appointmentsList);
  const loading = useSelector((state) => state.appointments.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppointmentsList());
  }, [dispatch, loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "completed":
        return "badge-success";
      case "upcoming":
        return "badge-info";
      case "canceled":
        return "badge-danger";
      case "rescheduled":
        return "badge-warning";
      case "pending":
        return "badge-dark";
      default:
        break;
    }
  };

  const getPaymentBadgeColor = (status) => {
    switch (status) {
      case "completed":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "canceled":
      case "failed":
        return "badge-danger";
      default:
        break;
    }
  };

  const getPage = (index) => {
    dispatch(
      fetchAppointmentsList({
        page: index,
      })
    );
  };

  return (
    <Container fluid>
      {/* Table */}
      <Row>
        <div className="col mt-7">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1 className="mb-0" style={{ color: "#ea526f" }}>
                {data.appointments.length}{" "}
                <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                  APPOINTMENTS
                </span>
              </h1>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DOCTOR
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PATIENT
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    SERVICE
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DATE
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    TIME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    BOOKED ON
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    FEE
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PAYMENT STATUS
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    APPOINTMENT STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {data.appointments.map((appointment) => {
                  return (
                    <tr key={appointment._id}>
                      <td>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <strong>
                            {appointment.doctor?.first_name}{" "}
                            {appointment.doctor?.last_name}
                          </strong>
                        </div>

                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {appointment.doctor?.country_code}{" "}
                          {appointment.doctor?.mobile_no}
                        </div>
                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {appointment.doctor?.email}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <strong>
                            {appointment.patient?.first_name}{" "}
                            {appointment.patient?.last_name}
                          </strong>
                        </div>
                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {appointment.patient?.country_code}{" "}
                          {appointment.patient?.mobile_no}
                        </div>
                        <div style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                          {appointment.patient?.email}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            textTransform: "capitalize",
                          }}
                        >
                          <strong>{appointment.appointment_type}</strong>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: "0.85rem" }}>
                          {formatDate(appointment.date).split(" - ")[0]}
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: "0.85rem" }}>
                          {appointment.start_time} - {appointment.end_time}
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: "0.85rem" }}>
                          {formatDate(appointment.createdAt)}
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: "0.85rem" }}>
                          {appointment.payment?.currency}{" "}
                          {appointment.payment?.amount}
                        </div>
                      </td>
                      <td className="text-left">
                        <Badge
                          className={getPaymentBadgeColor(
                            appointment.payment?.status
                          )}
                          pill
                          style={{
                            fontSize: "0.77rem",
                            textTransform: "capitalize",
                          }}
                        >
                          {appointment.payment?.status}
                        </Badge>
                      </td>
                      <td className="text-left">
                        <Badge
                          className={getBadgeColor(
                            appointment.appointment_status
                          )}
                          pill
                          style={{
                            fontSize: "0.77rem",
                            textTransform: "capitalize",
                          }}
                        >
                          {appointment.appointment_status}
                        </Badge>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          {/* <DropdownMenu className="dropdown-menu-arrow" right>
                                {appointment.appointment_status !== "active" && (
                                  <DropdownItem
                                    onClick={() => updateStatus(appointment._id, "active")}
                                  >
                                    Activate
                                  </DropdownItem>
                                )}
                                {appointment.appointment_status !== "deactivated" && (
                                  <DropdownItem
                                    onClick={() =>
                                      updateStatus(appointment._id, "deactivated")
                                    }
                                  >
                                    Deactivate
                                  </DropdownItem>
                                )}
                                <DropdownItem onClick={() => deleteAppointment(appointment._id)}>
                                  Delete
                                </DropdownItem>

                                 <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu> */}
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              {/* <Pagination
                    metadata={data.metadata}
                    getPage={getPage}
                  ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default AppointmentsList;
