import client from "../axios.js";

export async function fetchInvoices(params) {
  const page = params;
  console.log("bug 2", params);
  try {
    const response = await client.get(`/payment/list_invoices?page=${page}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;

    // return response.data;
  } catch (error) {
    console.log("[INVOICES FETCH ERR]:", error);
    throw new Error("An error occured while fetching invoices");
  }
}

export async function createInvoice(invoiceDetails) {
  invoiceDetails.customerNumber = invoiceDetails.customerNumber.split("+")[1];
  try {
    const response = await client.post("/payment/invoice", invoiceDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log("RESPONSE", data);
    return data;
  } catch (error) {
    console.log("INVOICE CREATION ERR]:", error);
    throw new Error("An error occured while creating an invoice");
  }
}
