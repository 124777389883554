import React from "react";
// import { Button } from "reactstrap";

import AppointmentsList from "components/Appointments/AppointmentsList";

const Appointments = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD APPOINTMENT
      </Button> */}
      <AppointmentsList></AppointmentsList>
    </>
  );
};

export default Appointments;
