import React from "react";

import AdminsList from "components/Admin/AdminsList";

const Admins = () => {
  return (
    <>
      <AdminsList></AdminsList>
    </>
  );
};

export default Admins;
