import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Media,
  Table,
  Container,
  Row,
  Button,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

import {
  fetchPrescriptionsList,
  updateResolutionStatus,
} from "store/slices/prescriptions";
import { formatDate } from "utils/date";
import SearchFilter from "components/SearchFilter/SearchFilter";

const PrescriptionList = () => {
  const [newPrescriptionModalOpen, setNewPrescriptionModalOpen] =
    useState(false);
  const data = useSelector((state) => state.prescriptions.prescriptionList);
  const loading = useSelector((state) => state.prescriptions.loading);

  const dispatch = useDispatch();
  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchPrescriptionsList());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.prescriptions);
    setUpdatedList(data.prescriptions);
  }, [data.prescriptions]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "resolved":
        return "badge-success";
      case "unresolved":
        return "badge-warning";
      case "completed":
        return "badge-dark";
      default:
        break;
    }
  };

  const toggleNewPrescriptionModal = () => {
    setNewPrescriptionModalOpen(!newPrescriptionModalOpen);
  };

  const updateStatus = (orderId, resolutionStatus) => {
    dispatch(
      updateResolutionStatus({
        orderId,
        resolutionStatus,
      })
    );
  };

  const viewOrder = (order) => {
    setSelectedOrder(order);
    setOrderDetailsModalOpen(true);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    setUpdatedList(
      filteredList.filter((row) => {
        const id = row._id.toString();
        const firstName = row.patient_details.firstname.toLowerCase();
        const lastName = row.patient_details.lastname.toLowerCase();
        const email = row.patient_details.email.toLowerCase();
        const city = row.patient_details.city.toLowerCase();
        const createdAt = row.createdAt.toString();
        const resolution = row.resolution.toLowerCase();
        const status = row.status.toLowerCase();

        const search = e.target.value.toLowerCase();
        return (
          id.includes(search) ||
          firstName.includes(search) ||
          lastName.includes(search) ||
          email.includes(search) ||
          city.includes(search) ||
          createdAt.includes(search) ||
          resolution.includes(search) ||
          status.includes(search)
        );
      })
    );
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="col">
                  <span className="mb-0" style={{ color: "#ea526f" }}>
                    {data.prescriptions.length}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      PRESCRIPTIONS
                    </span>
                    <span style={{ marginRight: "23rem", float: "right" }}>
                      <SearchFilter
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        handleChange={handleChange}
                      />
                    </span>
                  </span>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      PHARMACY ID
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      PRESCRIPTION IMAGE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      NAME
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CONTACT
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      ADDRESS
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      PLACED ON
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      RESOLUTION
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      STATUS
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {updatedList &&
                    updatedList.map((prescription) => {
                      const { patient_details } = prescription;

                      return (
                        <tr key={prescription._id}>
                          <td>
                            <span
                              className="mb-0 text-sm"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => viewOrder(prescription)}
                            >
                              {prescription._id.toUpperCase().slice(-8)}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              <img
                                src={prescription.prescription_file}
                                alt="drug_img"
                                width="50px"
                                height="50px"
                              />
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {patient_details.firstname}{" "}
                              {patient_details.lastname}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {patient_details.email}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {patient_details.city}
                            </span>
                          </td>
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {formatDate(prescription.createdAt)}
                            </span>
                          </td>
                          <td className="text-left">
                            <Badge
                              className={getBadgeColor(
                                prescription?.resolution
                              )}
                              pill
                              style={{
                                fontSize: "0.77rem",
                                textTransform: "capitalize",
                              }}
                            >
                              {prescription?.resolution}
                            </Badge>
                          </td>
                          <td className="text-left">
                            <Badge
                              className={getBadgeColor(prescription?.status)}
                              pill
                              style={{
                                fontSize: "0.77rem",
                                textTransform: "capitalize",
                              }}
                            >
                              {prescription?.status}
                            </Badge>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {prescription.status !== "resolved" && (
                                  <DropdownItem
                                    onClick={() =>
                                      updateStatus(prescription._id, "resolved")
                                    }
                                  >
                                    Set As Resolved
                                  </DropdownItem>
                                )}
                                {prescription.status !== "unresolved" && (
                                  <DropdownItem
                                    onClick={() =>
                                      updateResolutionStatus(
                                        prescription._id,
                                        "unresolved"
                                      )
                                    }
                                  >
                                    Set As Unresolved
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PrescriptionList;
