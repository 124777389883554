import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  // UncontrolledTooltip,
} from "reactstrap";

import Pagination from "../Pagination/Pagination";

import {
  fetchPatientsList,
  updatePatientProfile,
  deletePatientAccount,
} from "store/slices/patients";
import { formatDate } from "utils/date";
import SearchFilter from "components/SearchFilter/SearchFilter";

const PatientsList = (props) => {
  const data = useSelector((state) => state.patients.patientsList);
  const loading = useSelector((state) => state.patients.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPatientsList());
  }, [dispatch, loading]);

  useEffect(() => {
    console.log(data.patients);
    setFilteredList(data.patients);
    setUpdatedList(data.patients);
  }, [data]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const getPage = (index) => {
    dispatch(
      fetchPatientsList({
        page: index,
      })
    );
  };

  const updateStatus = (patientId, status) => {
    dispatch(
      updatePatientProfile({
        patientId,
        account_status: status,
      })
    );
  };

  const deletePatientProfile = (doctorId) => {
    dispatch(deletePatientAccount(doctorId));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setUpdatedList(
      filteredList.filter((patient) => {
        const firstName = patient.first_name.toLowerCase();
        const lastName = patient.last_name.toLowerCase();
        const email = patient.email.toLowerCase();
        const address = patient.location.address.toLowerCase();
        const mobile = patient.mobile_no.toLowerCase();
        const search = e.target.value.toLowerCase();
        return (
          firstName.includes(search) ||
          lastName.includes(search) ||
          email.includes(search) ||
          mobile.includes(search) ||
          address.includes(search)
        );
      })
    );
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0" style={{ display: "flex" }}>
                <h1 className="mb-0" style={{ color: "#ea526f" }}>
                  {updatedList.length}{" "}
                  <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                    PATIENTS
                  </span>
                </h1>
                <div style={{ margin: "0 auto" }}>
                  <SearchFilter
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleChange={handleChange}
                  />
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Name
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Contact
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Address
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Registered On
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Verification
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Status
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {updatedList.map((patient) => {
                    return (
                      <tr key={patient._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                src={
                                  patient.profile_image.length &&
                                  patient.profile_image !==
                                    "https://zuri-health-space.fra1.digitaloceanspaces.com/default_avatar.jpeg"
                                    ? patient.profile_image
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0 text-sm"
                                data-test="patient-name"
                              >
                                {patient.first_name} {patient.last_name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{patient.email}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {patient.country_code} {patient.mobile_no}
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{patient.location.country}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {patient.location.address} {patient.location.city}
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.75rem" }}>
                            {formatDate(patient.createdAt)}
                          </div>
                        </td>
                        <td className="text-left">
                          <div className="mb-1">
                            <Badge
                              className={
                                patient.email_verified
                                  ? "badge-success"
                                  : "badge-warning"
                              }
                              pill
                              style={{
                                fontSize: "0.77rem",
                                textTransform: "capitalize",
                              }}
                            >
                              Email
                            </Badge>
                          </div>
                          <div className="mb-1">
                            <Badge
                              className={
                                patient.mobile_no_verified
                                  ? "badge-success"
                                  : "badge-warning"
                              }
                              pill
                              style={{
                                fontSize: "0.77rem",
                                textTransform: "capitalize",
                              }}
                            >
                              Mobile
                            </Badge>
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(patient.account_status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {patient.account_status}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {patient.account_status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(patient._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {patient.account_status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(patient._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}

                              <DropdownItem
                                onClick={() =>
                                  deletePatientProfile(patient._id)
                                }
                              >
                                Delete
                              </DropdownItem>
                              {/* <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PatientsList;
