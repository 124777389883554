import client from "../axios";

export async function signUpPatient(patient) {
  try {
    const response = await client.post(
      "/auth/patient_signup",
      { patient },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PATIENT AUTH ERR]:", error);
    throw new Error("An error occured while signing up a patient");
  }
}

export async function fetchPatients(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/patient/patients_list?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("[Patient Fetch Error]:", error);
    throw new Error("An error occured while fetching patients");
  }
}

export async function updatePatient(data) {
  try {
    const { patientId, ...updates } = data;

    const formData = new FormData();
    formData.append("user", JSON.stringify(updates));

    await client.put(`/patient/patient_profile_edit/${patientId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("[Patient Update Error]:", error);
    throw new Error("An error occured while fetching patient");
  }
}

export async function getPatientProfile(id) {
  try {
    const response = await client.get(`/patient/patient_profile/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[PATIENT PROF ERR]:", error);
    throw new Error("An error occured while fetching patient's profile");
  }
}

export async function editPatientProfile(data) {
  try {
    const { patientId, ...edits } = data;

    const formData = new FormData();
    formData.append("user", JSON.stringify(edits));

    await client.put(`/patient/patient_profile_edit/${patientId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("[PATIENT EDIT ERR]:", error);
    throw new Error("An error occured while editing patient's profile");
  }
}

export async function fetchAppointmentList(id) {
  let appointments = [];
  try {
    const response = await client.get(`/patient/patient_appointments/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    appointments = response.data.appointments;
  } catch (error) {
    console.log("[APPOINTMENTS FETCH ERR]:", error);
    throw new Error("An error occured while fetching patients' appointmnets");
  }
  return appointments;
}

export async function getPatientApppointment(patientId, appointmentId) {
  try {
    const response = await client.get(
      `/patient/patient_get_appointment/${patientId}/${appointmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let appointment = response.data;
    console.log(appointment);
  } catch (error) {
    console.log("[PATIENT APPOINTMENT FETCH ERR]:", error);
    throw new Error("An error occured while fetching patient appointment");
  }
}

export async function deletePatient(id) {
  try {
    const response = await client.delete(`/patient/patient_delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a patient");
  }
}

export async function deactivatePatient(id) {
  try {
    const response = await client.put(`/patient/patient_deactivate/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PATIENT PROF ERR]:", error);
    throw new Error("An error occured while deactivating a patients account");
  }
}
