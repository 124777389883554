import React from "react";
// import { Button } from "reactstrap";

import CityList from "components/Cities/CitiesList";

const Cities = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD CITY
      </Button> */}
      <CityList></CityList>
    </>
  );
};

export default Cities;
