import React from "react";
import { Row, Form, FormGroup, Button, Badge, Input, Col } from "reactstrap";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faExternalLinkAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDoctorProfile } from "store/slices/doctors";
import { useEffect } from "react";
import { updateVerificationDetails } from "store/slices/doctors";

const DoctorAccountDetails = ({ doc, loading }) => {
  const [submitted, setSubmitted] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState({
    doctorId: doc._id,
    first_name: doc?.first_name || "",
    last_name: doc?.last_name || "",
    email: doc.email || "",
    country_code: doc?.country_code || "",
    mobile_no: doc?.mobile_no || "",
    gender: doc?.gender || "",
    local_currency: doc?.local_currency || "",
    date_of_birth: format(new Date(doc?.date_of_birth), "yyyy-MM-dd") || "",
    bio: doc?.bio || "",
    profile_image: "",
    location: {
      address: "",
      city: "",
      country: "",
      coordinates: {
        latitude: 0,
        longitude: 0,
      },
    },
  });

  console.log(doctorDetails.location);

  const getLatitudeAndLongitude = (value) => {
    if (value.split(",").length > 1) {
      return {
        latitude: value.split(",")[0],
        longitude: value.split(",")[1],
      };
    } else {
      return {
        latitude: 0,
        longitude: 0,
      };
    }
  };

  const [verificationDocuments, setVerificationDocuments] = useState({
    doctorId: doc._id,
    national_id: null,
    practice_license: null,
  });

  const dispatch = useDispatch();

  const handleUpdateDoctorsDetails = (e, params) => {
    e.preventDefault();
    try {
      dispatch(updateDoctorProfile(params));
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateVerificationDocuments = (e, params) => {
    e.preventDefault();
    try {
      dispatch(updateVerificationDetails(params));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, [loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDoctorDetails({ ...doctorDetails, [name]: value });
  };

  return (
    <>
      {doc && (
        <Form>
          <h6 className="heading-small text-muted mb-4">User Information</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    First Name{" "}
                    <Badge color={doc.first_name ? "success" : "warning"}>
                      {doc.first_name ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doctorDetails.first_name}
                    id="input-first-name"
                    name="first_name"
                    placeholder="First name"
                    type="text"
                    value={doctorDetails.first_name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Last Name{" "}
                    <Badge color={doc.last_name ? "success" : "warning"}>
                      {doc.last_name ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doctorDetails.last_name}
                    value={doctorDetails.last_name}
                    onChange={handleChange}
                    id="input-last-name"
                    placeholder="Last name"
                    name="last_name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first_name"
                  >
                    Country Dial Code{" "}
                    <Badge color={doc.country_code ? "success" : "warning"}>
                      {doc.country_code ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doctorDetails.country_code}
                    onChange={handleChange}
                    value={doctorDetails.country_code}
                    id="input-first_name"
                    placeholder="+254"
                    type="text"
                    name="country_code"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first_name"
                  >
                    Mobile No.{" "}
                    <Badge
                      color={doc.mobile_no_verified ? "success" : "warning"}
                    >
                      {doc.mobile_no_verified ? "Verified" : "Unverified"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doctorDetails.mobile_no}
                    value={doctorDetails.mobile_no}
                    onChange={handleChange}
                    id="input-first_name"
                    placeholder="Username"
                    type="text"
                    name="mobile_no"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email address{" "}
                    <Badge color={doc.email_verified ? "success" : "warning"}>
                      {doc.email_verified ? "Verified" : "Unverified"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    id="input-email"
                    defaultValue={doctorDetails.email}
                    value={doctorDetails.email}
                    onChange={handleChange}
                    name="email"
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-gender">
                    Gender{" "}
                    <Badge color={doc.gender ? "success" : "warning"}>
                      {doc.gender ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    // onChange={handleChange}
                    className="form-control-alternative"
                    id="input-gender"
                    name="gender"
                    type="select"
                    defaultValue={doctorDetails.gender}
                    value={doctorDetails.gender}
                    onChange={handleChange}
                  >
                    <option value="male">Male</option>
                    <option value="male">Female</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-local_currency"
                  >
                    Local Currency{" "}
                    <Badge color={doc.local_currency ? "success" : "warning"}>
                      {doc.country_code ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doctorDetails.local_currency}
                    value={doctorDetails.local_currency}
                    onChange={handleChange}
                    name="local_currency"
                    id="input-local_currency"
                    placeholder="Local Currency"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-account_status"
                  >
                    Account Status{" "}
                    <Badge className={getBadgeColor(doc.account_status)}>
                      {doc.account_status}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    // onChange={handleChange}
                    className="form-control-alternative"
                    id="input-account_status"
                    name="input-account_status"
                    type="select"
                    defaultValue={doc.account_status}
                  >
                    <option value="active">Active</option>
                    <option value="pending">Pending</option>
                    <option value="deactivated">Deactivated</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-dob">
                    Date Of Birth
                    <Badge color={doc.date_of_birth ? "success" : "warning"}>
                      {doc.date_of_birth ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doctorDetails.date_of_birth}
                    name="date_of_birth"
                    onChange={handleChange}
                    id="input-dob"
                    placeholder={format(new Date(), "yyyy-MM-dd")}
                    max={format(new Date(), "yyyy-MM-dd")}
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-dob">
                    Profile Image{" "}
                    <Badge color={doc.profile_image ? "success" : "warning"}>
                      {doc.profile_image ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    // defaultValue={doc.profile_image}
                    id="input-profile_image"
                    // placeholder={format(new Date(), "yyyy-MM-dd")}
                    // max={format(new Date(), "yyyy-MM-dd")}
                    type="file"
                    onChange={(e) =>
                      setDoctorDetails({
                        ...doctorDetails,
                        profile_image: e.target.files[0],
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-dob">
                    Bio{" "}
                    <Badge color={doc.bio ? "success" : "warning"}>
                      {doc.bio ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    placeholder="A brief intro about the doc ..."
                    rows="4"
                    defaultValue={doctorDetails.bio}
                    name="bio"
                    value={doctorDetails.bio}
                    onChange={handleChange}
                    type="textarea"
                  />
                </FormGroup>
              </Col>

              {/* SUBMIT USER DETAILS */}
              <Col lg="12" md="12" className="my-3 text-center">
                <Button
                  color="primary"
                  href="#pablo"
                  onClick={(e) => handleUpdateDoctorsDetails(e, doctorDetails)}
                >
                  <FontAwesomeIcon icon={faSave} /> SAVE
                </Button>
              </Col>
            </Row>
          </div>

          <hr className="my-4" />
          {/* Description */}
          <h6 className="heading-small text-muted mb-4">
            Verification Documents
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-national_id"
                  >
                    National ID{" "}
                    <Badge
                      color={
                        doc.verification_documents.national_id
                          ? "success"
                          : "warning"
                      }
                    >
                      {doc.verification_documents.national_id ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    // defaultValue={doc.profile_image}
                    id="input-national_id"
                    // placeholder={format(new Date(), "yyyy-MM-dd")}
                    // max={format(new Date(), "yyyy-MM-dd")}
                    type="file"
                    name="national_id"
                    onChange={(e) =>
                      setVerificationDocuments({
                        ...verificationDocuments,
                        national_id: e.target.files[0],
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-practice_certificate"
                  >
                    Practice Certificate{" "}
                    <Badge
                      color={
                        doc.verification_documents.practice_license
                          ? "success"
                          : "warning"
                      }
                    >
                      {doc.verification_documents.practice_license ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    // defaultValue={doc.profile_image}
                    id="input-practice_certificate"
                    // placeholder={format(new Date(), "yyyy-MM-dd")}
                    // max={format(new Date(), "yyyy-MM-dd")}
                    type="file"
                    name="practice_license"
                    onChange={(e) =>
                      setVerificationDocuments({
                        ...verificationDocuments,
                        practice_license: e.target.files[0],
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* SUBMIT VERIFICATION DOCUMENTS */}
            <Col lg="12" md="12" className="my-3 text-center">
              <Button
                color="primary"
                href="#pablo"
                onClick={(e) =>
                  handleUpdateVerificationDocuments(e, verificationDocuments)
                }
              >
                <FontAwesomeIcon icon={faSave} /> SAVE
              </Button>
            </Col>
          </div>

          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">Location</h6>
          <div className="pl-lg-4">
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Address{" "}
                    <Badge color={doc.location.address ? "success" : "warning"}>
                      {doc.location.address ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doc.location.address}
                    id="input-address"
                    placeholder="Address"
                    type="text"
                    onChange={(e) =>
                      setDoctorDetails({
                        ...doctorDetails,
                        location: {
                          ...doctorDetails.location,
                          address: e.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-city">
                    City{" "}
                    <Badge color={doc.location.city ? "success" : "warning"}>
                      {doc.location.city ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doc.location.city}
                    id="input-city"
                    placeholder="City"
                    type="text"
                    onChange={(e) =>
                      setDoctorDetails({
                        ...doctorDetails,
                        location: {
                          ...doctorDetails.location,
                          city: e.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    Country{" "}
                    <Badge color={doc.location.country ? "success" : "warning"}>
                      {doc.location.country ? "✓" : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    defaultValue={doc.location.country}
                    id="input-country"
                    placeholder="Country"
                    type="text"
                    onChange={(e) =>
                      setDoctorDetails({
                        ...doctorDetails,
                        location: {
                          ...doctorDetails.location,
                          country: e.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    Coordinates (lat, lng){" "}
                    <Badge
                      color={
                        doc.location?.coordinates?.longitude &&
                        doc.location?.coordinates?.latitude
                          ? "success"
                          : "warning"
                      }
                    >
                      {doc.location?.coordinates?.longitude &&
                      doc.location?.coordinates?.latitude
                        ? "✓"
                        : "x"}
                    </Badge>
                  </label>
                  <Input
                    disabled={loading}
                    className="form-control-alternative"
                    id="input-postal-code"
                    defaultValue={`${doc.location?.coordinates?.latitude}, ${doc.location?.coordinates?.longitude}`}
                    type="text"
                    onChange={(e) =>
                      setDoctorDetails({
                        ...doctorDetails,
                        location: {
                          ...doctorDetails.location,
                          coordinates: {
                            latitude: getLatitudeAndLongitude(e.target.value)
                              .latitude,
                            longitude: getLatitudeAndLongitude(e.target.value)
                              .longitude,
                          },
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>

              {/* SUBMIT LOCATION DETAILS */}
              <Col lg="12" md="12" className="my-3 text-center">
                <Button
                  color="primary"
                  href="#pablo"
                  onClick={(e) =>
                    handleUpdateDoctorsDetails(e, {
                      ...doctorDetails,
                      location: doctorDetails.location,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSave} /> SAVE
                </Button>
              </Col>
            </Row>
          </div>
          <hr className="my-4" />
          {/* Description */}
          <h6 className="heading-small text-muted mb-4">
            Professional History
          </h6>
          <div className="pl-lg-4">
            {/* SUBMIT PROFESSIONAL HISTORY DETAILS */}
            <Col lg="12" md="12" className="my-3 text-center">
              <Button
                disabled
                color="primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <FontAwesomeIcon icon={faSave} /> SAVE
              </Button>
            </Col>
          </div>
          <hr className="my-4" />
          {/* Description */}
          <h6 className="heading-small text-muted mb-4">Services Offered</h6>
          <div className="pl-lg-4">
            {/* SUBMIT SERVICES OFFERED DETAILS */}
            <Col lg="12" md="12" className="my-3 text-center">
              <Button
                disabled
                color="primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <FontAwesomeIcon icon={faSave} /> SAVE
              </Button>
            </Col>
          </div>
        </Form>
      )}
    </>
  );
};

export default DoctorAccountDetails;
