import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    title: "",
    message: "",
    variant: "",
  },
  reducers: {},
});

export default toastSlice.reducer;
