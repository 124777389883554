import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchPatients,
  updatePatient,
  deletePatient,
} from "../../services/api/patients";

export const fetchPatientsList = createAsyncThunk(
  "patients/fetchList",
  async (params) => {
    const response = await fetchPatients(params);
    return response;
  }
);

export const updatePatientProfile = createAsyncThunk(
  "patients/updateProfile",
  async (params) => {
    const response = await updatePatient(params);
    return response;
  }
);

export const deletePatientAccount = createAsyncThunk(
  "patients/deleteAccount",
  async (params) => {
    const response = await deletePatient(params);
    return response;
  }
);

export const patientsSlice = createSlice({
  name: "patients",
  initialState: {
    loading: false,
    patientsList: {
      patients: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatientsList.fulfilled, (state, action) => {
      state.patientsList = action.payload;
    });

    builder.addCase(updatePatientProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updatePatientProfile.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePatientAccount.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deletePatientAccount.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default patientsSlice.reducer;
