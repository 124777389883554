import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { fetchLabsList } from "store/slices/labs";
import { fetchTestLists } from "store/slices/labs";

const UpdateWellnessPackageModal = ({
  handleSubmit,
  toggleUpdateWellnessPackageModal,
  activeWellnessPackage,
}) => {
  const [wellnessPackageDetails, setWellnessPackageDetails] = useState({
    name: activeWellnessPackage.name,
    description: activeWellnessPackage.description,
    price: activeWellnessPackage.price,
    test_center_id: activeWellnessPackage.testcenter._id,
    discount: activeWellnessPackage.discount,
    home_test: activeWellnessPackage.home_test,
    tests: activeWellnessPackage.tests.map((t) => t._id),
  });
  const [wellnessTestArray, setWellnessTestArray] = useState([]);

  const { test_centers } = useSelector((state) => state.labs.labsList);
  const { test_products } = useSelector((state) => state.labs.testList);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsList());
    dispatch(fetchTestLists());
    setWellnessTestArray([...wellnessPackageDetails.tests]);
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setWellnessPackageDetails({
      ...wellnessPackageDetails,
      [name]: value,
    });
  };

  const handleChekbox = (event) => {
    setWellnessPackageDetails({
      ...wellnessPackageDetails,
      home_test: event.target.checked,
    });
  };

  const handleWellnessPackageTests = (event) => {
    const { value } = event.target;
    setWellnessTestArray([...wellnessTestArray, value]);
    setWellnessPackageDetails({
      ...wellnessPackageDetails,
      tests: [...wellnessPackageDetails.tests, value],
    });
  };

  const submitForm = () => {
    const payload = {
      id: activeWellnessPackage._id,
      test_center_id: activeWellnessPackage.testcenter._id,
      payload: wellnessPackageDetails,
    };
    handleSubmit(payload);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleUpdateWellnessPackageModal()}>
        <strong style={{ fontSize: "1.00rem" }}>Update Wellness Package</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="test_center_id">
                  <strong>Test Centre</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="test_center_id"
                  name="test_center_id"
                  type="select"
                  className="form-control-alternative"
                  value={wellnessPackageDetails.test_center_id || ""}
                >
                  <option defaultValue="" disabled>
                    Select Test Centre
                  </option>
                  {test_centers &&
                    test_centers.map((lab) => (
                      <option
                        defaultValue={lab._id}
                        key={lab._id}
                        value={lab._id}
                      >
                        {lab.center_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Wellness Package Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder="Wellness Package Name"
                  type="text"
                  value={wellnessPackageDetails.name || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>Price</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="price"
                  placeholder="Price"
                  name="price"
                  type="text"
                  value={wellnessPackageDetails.price || ""}
                  //   min="1"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="description">
                  <strong>Description</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="description"
                  name="description"
                  type="textarea"
                  placeholder="Package Description..."
                  className="form-control-alternative"
                  value={wellnessPackageDetails.description || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="discount">
                  <strong>Discount</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="discount"
                  placeholder="Discount"
                  name="discount"
                  type="text"
                  value={wellnessPackageDetails.discount || ""}
                  //   max="100"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="image">
                  <strong>Tests</strong>
                </Label>
                <Input
                  onChange={handleWellnessPackageTests}
                  className="form-control-alternative"
                  id="tests"
                  name="tests"
                  placeholder="Tests"
                  type="select"
                >
                  <option defaultValue="" disabled>
                    Select Package Tests
                  </option>
                  {test_products &&
                    test_products.map((test) => (
                      <option key={test._id} value={test._id}>
                        {test.name}
                      </option>
                    ))}
                </Input>
                {wellnessTestArray.map((test, index) => (
                  <div key={index}>
                    <p>
                      <small>{test}</small>
                      <span
                        style={{
                          paddingLeft: "40px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setWellnessTestArray(
                            wellnessTestArray.filter((item) => item !== test)
                          );
                          setWellnessPackageDetails({
                            ...wellnessPackageDetails,
                            tests: [
                              ...wellnessPackageDetails.tests.filter(
                                (item) => item !== test
                              ),
                            ],
                          });
                        }}
                      >
                        x
                      </span>
                    </p>
                  </div>
                ))}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="my-2">
              <FormGroup check>
                <Input
                  type="checkbox"
                  onChange={handleChekbox}
                  id="home_test"
                  name="home_test"
                  className="form-control-alternative"
                  checked={wellnessPackageDetails.home_test || false}
                />
                <Label check style={{ fontSize: "0.85rem" }} for="home_test">
                  <strong>Is Home Test available for this package ?</strong>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={submitForm}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleUpdateWellnessPackageModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

export default UpdateWellnessPackageModal;
