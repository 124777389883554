import client from "../axios.js";

export async function fetchAdverts(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/adverts/list_adverts?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[ADVERTS FETCH ERR]:", error);
    throw new Error("An error occured while fetching adverts");
  }
}
