import client from "services/axios";

export async function fetchPrescriptions(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(`pharmacy/direct_prescription_uploads`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("[PRESCRIPTION FETCH ERR]:", error);
    throw new Error("An error occured while fetching prescriptions");
  }
}

export async function updatePrescriptionResolutionStatus({ orderId, status }) {
  try {
    const response = await client.post(
      `/pharmacy/pharmacy_order_status/${orderId}`,
      { order_status: status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    console.log(data);
  } catch (error) {
    console.log("RESOLUTION STATUS UPDATE ERR]:", error);
    throw new Error("An error occured while updating resolution status");
  }
}
