import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchPharmacyProductCategories,
  // editTestCategory,
  // deleteTestCategory,
  createPharmacyProductCategory,
  updatePharmacyProductCategory,
  deletePharmacyProductCategory,
} from "../../services/api/pharmacies";

export const fetchCategoryList = createAsyncThunk(
  "categories/fetchTestCategories",
  async (params) => {
    const response = await fetchPharmacyProductCategories(params);
    return response;
  }
);

export const createNewPharmacyCategory = createAsyncThunk(
  "categories/createNewPharmacyCategory",
  async (params) => {
    const response = await createPharmacyProductCategory(params);
    return response;
  }
);
export const updatePharmacyCategory = createAsyncThunk(
  "categories/updatePharmacyCategory",
  async (params) => {
    const response = await updatePharmacyProductCategory(params);
    return response;
  }
);

// export const updateStatus = createAsyncThunk(
//   "categories/updateCategory",
//   async (params) => {
//     const response = await editTestCategory(params);
//     return response;
//   }
// );

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (params) => {
    const response = await deletePharmacyProductCategory(params);
    return response;
  }
);

export const categoriesSlice = createSlice({
  name: "pharmacyProductCategories",
  initialState: {
    loading: false,
    categoriesList: {
      pharmacy_categories: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryList.fulfilled, (state, action) => {
      state.categoriesList = action.payload;
    });
    /* builder.addCase(updateStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteCategory.fulfilled, (state) => {
      state.loading = false;
    }); */
  },
});

export default categoriesSlice.reducer;
