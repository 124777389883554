import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TextArea,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { fetchCountriesList } from "store/slices/countries";

const NewCityForm = ({ handleSubmit, toggleNewCityModal }) => {
  const [cityDetails, setCityDetails] = useState({
    city_name: "",
    city_code: "",
    country: "",
  });

  const { countries } = useSelector((state) => state.countries.countriesList);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCountriesList());
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCityDetails({ ...cityDetails, [name]: value });
  };

  const submitForm = () => {
    handleSubmit(cityDetails);
  };

  return (
    <>
      <ModalHeader toggle={() => toggleNewCityModal()}>
        <strong style={{ fontSize: "1.00rem" }}>New City</strong>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>City Name</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="city_name"
                  name="city_name"
                  placeholder="City Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="name">
                  <strong>City Code</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  className="form-control-alternative"
                  id="city_code"
                  name="city_code"
                  placeholder="City Code"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label style={{ fontSize: "0.85rem" }} for="country">
                  <strong>Country</strong>
                </Label>
                <Input
                  onChange={handleChange}
                  id="country"
                  name="country"
                  type="select"
                  className="form-control-alternative"
                >
                  <option value="" disabled selected>
                    Select Country
                  </option>
                  {countries &&
                    countries.map((country) => (
                      <option value={country._id} key={country._id}>
                        {country.country_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => submitForm()}>
          Submit
        </Button>{" "}
        <Button onClick={() => toggleNewCityModal()}>Cancel</Button>
      </ModalFooter>
    </>
  );
};
export default NewCityForm;
