import client from "../axios.js";

export async function fetchLanguages(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(`/language?page=${params.page}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("[LANGUAGES FETCH ERR]:", error);
    throw new Error("An error occured while fetching languages");
  }
}

export async function editLanguage(data) {
  try {
    const { languageId, ...updates } = data;

    await client.put(`/language/edit/${languageId}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("[Language EDIT ERR]:", error);
    throw new Error("An error occured while editing a language");
  }
}

export async function deleteLanguage(id) {
  try {
    const response = await client.delete(`/language${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a language");
  }
}
export async function createLanguage(language) {
  try {
    const response = await client.post(
      "/language/",
      language,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("LANGUAGE CREATION ERR]:", error);
    throw new Error("An error occured while creating a language");
  }
}
