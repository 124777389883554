import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Badge,
} from "reactstrap";

const OrderDetails = ({
  order,
  close,
  getStatusBadgeColor,
  getPaymentBadgeColor,
}) => {
  return (
    <>
      <ModalHeader>
        <strong style={{ fontSize: "1.00rem" }}>
          Order No {order._id.toUpperCase().slice(-8)}
        </strong>
      </ModalHeader>
      <ModalBody>
        <Row className="mb-2">
          <Col md="4" className="pt-2">
            <h5>Order Status:</h5>
            <Badge
              className={getStatusBadgeColor(order.order_status)}
              pill
              style={{
                fontSize: "0.77rem",
                textTransform: "capitalize",
              }}
            >
              {order.order_status}
            </Badge>
          </Col>
          <Col md="4" className="pt-2">
            <h5>Payment Status:</h5>
            <Badge
              className={getPaymentBadgeColor(order.payment?.status)}
              pill
              style={{
                fontSize: "0.77rem",
                textTransform: "capitalize",
              }}
            >
              {order.payment?.status}
            </Badge>
          </Col>
          <Col md="4" className="pt-2">
            <h5>Amount:</h5>
            <span>
              {order.currency} {order.amount}
            </span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6" className="pt-4">
            <h5>Customer Name:</h5>
            <span className="mb-0 text-sm">{order.user_info?.name}</span>
          </Col>
          <Col md="6" className="pt-4">
            <h5>Customer Email:</h5>
            <span className="mb-0 text-sm">{order.user_info?.email}</span>
          </Col>
          <Col md="6" className="pt-4">
            <h5>Customer Mobile:</h5>
            <span className="mb-0 text-sm">{order.user_info?.mobile_no}</span>
          </Col>
          <Col md="6" className="pt-4">
            <h5>Delivery Address:</h5>
            <span className="mb-0 text-sm">
              {order.delivery_address?.address_title}:
            </span>
            <br />
            <span className="mb-0 text-sm">
              {order.delivery_address?.address}
            </span>
            <br />
            <span className="mb-0 text-sm">
              Landmark: {order.delivery_address?.landmark}
            </span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6" className="pt-4">
            <h5>Lab Name:</h5>
            <span className="mb-0 text-sm">
              {order.test_center_id?.center_name}
            </span>
          </Col>
          <Col md="6" className="pt-4">
            <h5>Lab Email:</h5>
            <span className="mb-0 text-sm">{order.test_center_id?.email}</span>
          </Col>
          <Col md="6" className="pt-4">
            <h5>Lab Mobile:</h5>
            <span className="mb-0 text-sm">
              {order.test_center_id?.country_code}{" "}
              {order.test_center_id?.mobile_no}
            </span>
          </Col>
          <Col md="6" className="pt-4">
            <h5>Lab Address:</h5>
            <span className="mb-0 text-sm">
              {order.test_center_id?.location.address}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="pt-4">
            <h5>Items:</h5>
          </Col>
          <Col md="12">
            <i>WIP - Please check later</i>
            {/* {order.details.map(item => (
              <>
                <li>
                  <span className="mb-0 text-sm" style={{textTransform: "capitalize"}}>
                    {item.product_id.name} - {item.qty} pieces - @ {order.currency} {item.unit_price}
                  </span>
                </li>
              </>
            ))} */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => close()}>Close</Button>
      </ModalFooter>
    </>
  );
};

export default OrderDetails;
