import client from "../axios.js";

export async function fetchCountries(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(`/country?page=${params.page}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("[COUNTRIES FETCH ERR]:", error);
    throw new Error("An error occured while fetching countries");
  }
}

export async function editCountry(data) {
  try {
    const { countryId, ...updates } = data;

    await client.put(`/country/edit/${countryId}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("[COUNTRY EDIT ERR]:", error);
    throw new Error("An error occured while editing a country");
  }
}

export async function deleteCountry(id) {
  try {
    const response = await client.delete(`/country${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a country");
  }
}
export async function createCountry(country) {
  try {
    const response = await client.post(
      "/country/",
      country,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("COUNTRY CREATION ERR]:", error);
    throw new Error("An error occured while creating a country");
  }
}
