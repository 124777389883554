import client from "../axios";

/* LABS ACCOUNTS */

export async function createLab(lab) {
  try {
    const response = await client.post("/auth/create_test_center", lab, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("LAB CREATION ERR]:", error);
    throw new Error("An error occured while creating a lab profile");
  }
}
export async function fetchLabs(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_diagnostic/test_center_list?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("[Labs Fetch Error]:", error);
    throw new Error("An error occured while fetching labs");
  }
}

export async function editTestCenterProfile(data) {
  try {
    const { labId, ...updates } = data;

    await client.put(`/test_diagnostic/test_center_edit/${labId}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("[TEST CENTER EDIT ERR]:", error);
    throw new Error("An error occured while editing a test center");
  }
}
export async function deleteTestCenter(id) {
  try {
    const response = await client.delete(`/delete_test_center${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a test center");
  }
}

/* ORDERS */
export async function fetchLabsOrders(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_diagnostic/test_center_orders?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[LABS LIST FETCH ERR]:", error);
    throw new Error("An error occured while fetching test centers");
  }
}

export async function updateLabOrderStatus({ orderId, status }) {
  try {
    await client.post(
      `/test_diagnostic/test_center_order_status/${orderId}`,
      { order_status: status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log("ORDER STATUS UPDATE ERR]:", error);
    throw new Error("An error occured while updating order status");
  }
}

/* PRODUCTS */
export async function fetchLabsProducts(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_diagnostic/test_product_list?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[TEST LIST FETCH ERR]:", error);
    throw new Error("An error occured while fetching test list");
  }
}

export async function fetchLabsTestKits(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/labs_and_diagnostic/testkit?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[TEST KIT FETCH ERR]:", error);
    throw new Error("An error occured while fetching test kits");
  }
}

export async function updateTestKit(data) {
  try {
    const { id, ...updates } = data;

    await client.put(`/labs_and_diagnostic/testkit/${id}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("[TEST KIT UPDATE ERR]:", error);
    throw new Error("An error occured while updating a test kit");
  }
}
export async function deleteTestKit(id) {
  try {
    const response = await client.delete(`/labs_and_diagnostic/testkit/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a test kit");
  }
}

export async function createTestKit(payload) {
  try {
    const response = await client.post(
      "/labs_and_diagnostic/testkit",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let data = response.data;
    console.log("api call response ===>", data);
  } catch (error) {
    console.log("[TEST KIT CREATION ERR]:", error);
    throw new Error("An error occured while creating a test kit");
  }
}

export async function updateTestKitRecord(data) {
  try {
    const response = await client.get(
      `/labs_and_diagnostic/testkit/${data.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = response.data;
    console.log(data);
    return data;
  } catch (error) {
    console.log("[GET TEST KIT FAILED]:", error);
    throw new Error("An error occured while getting a test kit");
  }
}

export async function fetchLabsWellnessPackages(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_diagnostic/display_wellness_packages?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("An error occured while fetching wellness packages");
  }
}
export async function fetchTestsProducts(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_diagnostic/test_product_list?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("An error occured while fetching test products");
  }
}
export async function fetchTestsCategory(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/test_category/category_list?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("An error occured while fetching test category");
  }
}

export async function createWellnessPackage(payload) {
  try {
    const response = await client.post(
      "/test_diagnostic/center_package",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("An error occured while creating wellness package");
  }
}

export async function deleteWellnessPackage(data) {
  const { id, test_center_id } = data;
  try {
    const response = await client.delete(
      `/test_diagnostic/test_center_package_delete/${id}/${test_center_id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting wellness package");
  }
}
export async function deleteTestProduct(id) {
  try {
    const response = await client.delete(
      `/test_diagnostic/test_product_delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting test product");
  }
}

export async function updateWellnessPackage(data) {
  const { id, test_center_id, payload } = data;
  try {
    const response = await client.put(
      `/test_diagnostic/test_center_package_edit/${id}/${test_center_id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("An error occured while updating wellness package");
  }
}
export async function updateTestProducts(data) {
  const { id, payload } = data;
  try {
    const response = await client.put(
      `/test_diagnostic/test_product_profile_edit/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("An error occured while updating test products");
  }
}
