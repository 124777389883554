import client from "../axios.js";

export async function signUpDoctor(doctor) {
  try {
    const response = await client.post("/auth/doctor_signup", doctor, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("DOCTOR AUTH ERR]:", error);
    throw new Error("An error occured while signing up a doctor");
  }
}

export async function fetchDoctors(
  params = {
    page: 1,
    filters: {},
    search_word: null,
  }
) {
  try {
    const response = await client.get(
      `/doctor/doctors_list?getAll=true&page=${params.page}${
        params.search_word ? `&search_word=${params.search_word}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("[DOCTOR FETCH ERR]:", error);
    throw new Error("An error occured while fetching doctors");
  }
}

export async function getDoctorProfile(id) {
  try {
    const response = await client.get(`/doctor/doctor_profile/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("[DOCTOR PROF ERR]:", error);
    throw new Error("An error occured while fetching doctor's profile");
  }
}

export async function updateDoctor(data) {
  try {
    const { doctorId, ...updates } = data;

    const formData = new FormData();
    formData.append("profile_image", updates.profile_image);
    formData.append("user", JSON.stringify(updates));

    await client.put(`/doctor/doctor_profile_edit/${doctorId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (error) {
    console.log("[DOCTOR UPD ERR]:", error);
    throw new Error("An error occured while updating doctor");
  }
}

export async function deleteDoctor(id) {
  try {
    const response = await client.delete(`/doctor/doctor_delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    throw new Error("An error occured while deleting a doctor");
  }
}

export async function fetchAppointments(id) {
  let appointments = [];
  try {
    const response = await client.get(`/doctor/doctor_appointments/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    appointments = response.data.appointments;
    console.log(appointments);
  } catch (error) {
    console.log("[APPOINTMENT FETCH ERR]:", error);
    throw new Error("An error occured while fetching doctor's appointments");
  }
  return appointments;
}

export async function deactivateDoctor(id) {
  try {
    const response = await client.put(`doctor/doctor_deactivate/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[DOCTOR PROF ERR]:", error);
    throw new Error("An error occured while deactivating doctor's account");
  }
}

export async function uploadVerificationDocuments(data) {
  try {
    const { doctorId, ...documents } = data;

    const formData = new FormData();
    formData.append("practice_license", documents.practice_license);
    formData.append("national_id", documents.national_id);

    const response = await client.post(
      `doctor/upload_verification_docs/${doctorId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.log("[VERIFICATION DOCUMENTS UPLOAD ERROR]:", error);
    throw new Error(
      "An error occured while uploading the verification documents"
    );
  }
}
