import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchLabs,
  editTestCenterProfile,
  deleteTestCenter,
  fetchLabsOrders,
  updateLabOrderStatus,
  fetchLabsProducts,
  createLab,
  fetchLabsTestKits,
  updateTestKit,
  deleteTestKit,
  createTestKit,
  updateTestKitRecord,
  fetchLabsWellnessPackages,
  fetchTestsProducts,
  createWellnessPackage,
  deleteWellnessPackage,
  updateWellnessPackage,
  fetchTestsCategory,
  updateTestProducts,
  deleteTestProduct,
} from "../../services/api/labs";

export const createLabAccount = createAsyncThunk(
  "labs/createLab",
  async (params) => {
    const response = await createLab(params);
    return response;
  }
);

export const fetchLabsList = createAsyncThunk(
  "labs/fetchLabs",
  async (params) => {
    const response = await fetchLabs(params);
    return response;
  }
);

export const updateLabProfile = createAsyncThunk(
  "labs/updateProfile",
  async (params) => {
    const response = await editTestCenterProfile(params);
    return response;
  }
);

export const deleteLabAccount = createAsyncThunk(
  "labs/deleteAccount",
  async (params) => {
    const response = await deleteTestCenter(params);
    return response;
  }
);

export const fetchLabsOrdersList = createAsyncThunk(
  "labs/fetchOrders",
  async (params) => {
    const response = await fetchLabsOrders(params);
    return response;
  }
);

export const updateOrderStatus = createAsyncThunk(
  "labs/updateOrderStatus",
  async (params) => {
    const response = await updateLabOrderStatus(params);
    return response;
  }
);

export const fetchLabsProductsList = createAsyncThunk(
  "labs/fetchProducts",
  async (params) => {
    const response = await fetchLabsProducts(params);
    return response;
  }
);

export const fetchTestKits = createAsyncThunk(
  "labs/fetchTestKits",
  async (params) => {
    const response = await fetchLabsTestKits(params);
    return response;
  }
);

export const editTestKit = createAsyncThunk(
  "labs/updateTestKit",
  async (params) => {
    const response = await updateTestKit(params);
    return response;
  }
);

export const removeTestKit = createAsyncThunk(
  "labs/deleteTestKit",
  async (params) => {
    const response = await deleteTestKit(params);
    return response;
  }
);

export const addTestKit = createAsyncThunk(
  "labs/createTestKit",
  async (params) => {
    const response = await createTestKit(params);
    return response;
  }
);

export const updateTestKitProduct = createAsyncThunk(
  "labs/updateTestKit",
  async (params) => {
    const response = await updateTestKitRecord(params);
    return response;
  }
);

export const fetchWellnessPackages = createAsyncThunk(
  "labs/fetchWellnessPackages",
  async (params) => {
    const response = await fetchLabsWellnessPackages(params);
    return response;
  }
);

export const fetchTestLists = createAsyncThunk(
  "labs/fetchTestLists",
  async (params) => {
    const response = await fetchTestsProducts(params);
    return response;
  }
);

export const fetchTestCategoryList = createAsyncThunk(
  "labs/fetchTestCategoryList",
  async (params) => {
    const response = await fetchTestsCategory(params);
    return response;
  }
);

export const addWellnessPackage = createAsyncThunk(
  "labs/createWellnessPackage",
  async (params) => {
    const response = await createWellnessPackage(params);
    if (response.status === 200) {
      alert("Wellness Package created successfully");
      window.location.reload();
    }
    return response;
  }
);

export const removeWellnessPackage = createAsyncThunk(
  "labs/removeWellnessPackage",
  async (params) => {
    const response = await deleteWellnessPackage(params);
    if (response.status === 200) {
      alert("Wellness Package deleted successfully");
      window.location.reload();
    }
    return response;
  }
);

export const deleteProduct = createAsyncThunk(
  "labs/removeTestProduct",
  async (params) => {
    const response = await deleteTestProduct(params);
    if (response.status === 200) {
      alert("Test Product removed successfully");
      window.location.reload();
    }
    return response;
  }
);

export const editWellnessPackage = createAsyncThunk(
  "labs/updateWellnessPackage",
  async (params) => {
    const response = await updateWellnessPackage(params);
    if (response.status === 200) {
      alert("Wellness Package updated successfully");
      window.location.reload();
    }
    return response;
  }
);

export const updateTestProduct = createAsyncThunk(
  "labs/updateTestProduct",
  async (params) => {
    const response = await updateTestProducts(params);
    if (response.status === 200) {
      alert("Test Product updated successfully");
      window.location.reload();
    }
    return response;
  }
);

export const labsSlice = createSlice({
  name: "labs",
  initialState: {
    loading: false,
    labsList: {
      test_centers: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    labsOrdersList: {
      orders: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    labsProductsList: {
      test_products: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    labsTestKitList: {
      testKits: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    wellnessPackagesList: {
      centerPackages: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    testList: {
      test_products: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
    testCategoryList: {
      test_categories: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLabsList.fulfilled, (state, action) => {
      state.labsList = action.payload;
    });

    builder.addCase(fetchLabsOrdersList.fulfilled, (state, action) => {
      state.labsOrdersList = action.payload;
    });

    builder.addCase(fetchLabsProductsList.fulfilled, (state, action) => {
      state.labsProductsList = action.payload;
    });

    builder.addCase(updateOrderStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateOrderStatus.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateLabProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateLabProfile.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLabAccount.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteLabAccount.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchTestKits.fulfilled, (state, action) => {
      state.labsTestKitList = action.payload;
    });

    builder.addCase(fetchWellnessPackages.fulfilled, (state, action) => {
      state.wellnessPackagesList = action.payload;
    });

    builder.addCase(fetchTestLists.fulfilled, (state, action) => {
      state.testList = action.payload;
    });

    builder.addCase(fetchTestCategoryList.fulfilled, (state, action) => {
      state.testCategoryList = action.payload;
    });
  },
});

export default labsSlice.reducer;
