import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchTestCategories,
  editTestCategory,
  deleteTestCategory,
  createTestCategory,
} from "../../services/api/testCategories";

export const createNewTestCategory = createAsyncThunk(
  "pharmacies/createTestCategory",
  async (params) => {
    const response = await createTestCategory(params);
    return response;
  }
);

export const fetchCategoryList = createAsyncThunk(
  "testCategories/fetchTestCategories",
  async (params) => {
    const response = await fetchTestCategories(params);
    return response;
  }
);

export const updateStatus = createAsyncThunk(
  "testCategories/updateCategory",
  async (params) => {
    const response = await editTestCategory(params);
    return response;
  }
);

export const deleteCategory = createAsyncThunk(
  "testCategories/deleteCategory",
  async (params) => {
    const response = await deleteTestCategory(params);
    return response;
  }
);

export const categoriesSlice = createSlice({
  name: "testCategories",
  initialState: {
    loading: false,
    categoriesList: {
      test_categories: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryList.fulfilled, (state, action) => {
      state.categoriesList = action.payload;
    });
    builder.addCase(updateStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteCategory.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default categoriesSlice.reducer;
