import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,
  Button,
  Form,
  FormGroup,
  // UncontrolledTooltip,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import NewDoctorForm from "components/Doctors/Modals/AddDoctorModal";
import Pagination from "components/Pagination/Pagination";
import {
  createDoctorAccount,
  fetchDoctorsList,
  updateDoctorProfile,
  deleteDoctorAccount,
} from "store/slices/doctors";
import { formatDate } from "utils/date";

const DoctorsList = (props) => {
  const history = useHistory();

  const [newDoctorModalOpen, setNewDoctorModalOpen] = useState(false);
  const [searchName, setSearchName] = useState(false);

  const data = useSelector((state) => state.doctors.doctorsList);
  const loading = useSelector((state) => state.doctors.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchDoctorsList({
        page: data.metadata.current_page,
        search_word: searchName,
      })
    );
  }, [dispatch, loading, searchName]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };
  const toggleNewDoctorModal = () => {
    setNewDoctorModalOpen(!newDoctorModalOpen);
  };

  const renderSpecializations = (specializations) => {
    if (specializations.length === 1)
      return specializations[0].specialization_name;
    if (!specializations.length) return "None";
    if (specializations.length > 1)
      return `${specializations[0].specialization_name} + ${specializations.length} more`;
  };

  const getPage = (index) => {
    dispatch(
      fetchDoctorsList({
        page: index,
        search_word: searchName,
      })
    );
  };

  const updateStatus = (doctorId, status) => {
    dispatch(
      updateDoctorProfile({
        doctorId,
        account_status: status,
      })
    );
  };

  const deleteDoctorProfile = (doctorId) => {
    dispatch(deleteDoctorAccount(doctorId));
  };
  const createNewDoctor = (details) => {
    try {
      dispatch(createDoctorAccount(details));
      toggleNewDoctorModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.metadata.total}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        DOCTORS
                      </span>
                    </h1>
                  </div>
                  <div className="col">
                    <Form>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            type="text"
                            onChange={(event) => {
                              setSearchName(event.target.value);
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </div>
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewDoctorModalOpen(true)}
                    >
                      + ADD DOCTOR
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewDoctorModal()}
                      isOpen={newDoctorModalOpen}
                    >
                      <NewDoctorForm
                        handleSubmit={createNewDoctor}
                        toggleNewDoctorModal={toggleNewDoctorModal}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Name
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Contact
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Specialization
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Address
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Registered On
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Verification
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      Acc. Status
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.doctors.map((doc) => {
                    return (
                      <tr key={doc._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(
                                  `/admin/doctor-profile/${doc._id}`
                                );
                              }}
                            >
                              <img
                                src={
                                  doc.profile_image.length &&
                                  doc.profile_image !==
                                    "https://zuri-health-space.fra1.digitaloceanspaces.com/default_avatar.jpeg"
                                    ? doc.profile_image
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0 text-sm"
                                data-test="doctor-name"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(
                                    `/admin/doctor-profile/${doc._id}`
                                  );
                                }}
                              >
                                {doc.first_name} {doc.last_name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{doc.email}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {doc.country_code} {doc.mobile_no}
                          </div>
                        </td>
                        <td>
                          <div>
                            <strong>
                              {renderSpecializations(
                                doc.professional_history.specializations
                              )}
                            </strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            <strong>{doc.location.country}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {doc.location.address} {doc.location.city}
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.75rem" }}>
                            {formatDate(doc.createdAt)}
                          </div>
                        </td>
                        <td className="text-left">
                          <div className="mb-1">
                            <Badge
                              className={
                                doc.email_verified
                                  ? "badge-success"
                                  : "badge-warning"
                              }
                              pill
                              style={{
                                fontSize: "0.77rem",
                                textTransform: "capitalize",
                              }}
                            >
                              Email
                            </Badge>
                          </div>
                          <div className="mb-1">
                            <Badge
                              className={
                                doc.mobile_no_verified
                                  ? "badge-success"
                                  : "badge-warning"
                              }
                              pill
                              style={{
                                fontSize: "0.77rem",
                                textTransform: "capitalize",
                              }}
                            >
                              Mobile
                            </Badge>
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(doc.account_status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {doc.account_status}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {doc.account_status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(doc._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {doc.account_status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(doc._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() => deleteDoctorProfile(doc._id)}
                              >
                                Delete
                              </DropdownItem>

                              {/* <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DoctorsList;
