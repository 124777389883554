import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row,
  Modal,
} from "reactstrap";

import Pagination from "components/Pagination/Pagination";

import SearchFilter from "components/SearchFilter/SearchFilter";
import { fetchWellnessPackages } from "store/slices/labs";
import NewWellnessPackageModal from "./Modals/NewWellnessPackageModal";
import { addWellnessPackage } from "store/slices/labs";
import { removeWellnessPackage } from "store/slices/labs";
import UpdateWellnessPackageModal from "./Modals/UpdateWellnessPackageModal";
import { editWellnessPackage } from "store/slices/labs";

const WellnessPackagesList = (props) => {
  const [newWellnessPackageModalOpen, setNewWellnessPackageModalOpen] =
    useState(false);
  const [updateWellnessPackageModalOpen, setUpdateWellnessPackageModalOpen] =
    useState(false);
  const [activeWellnessPackage, setActiveWellnessPackage] = useState({});

  const data = useSelector((state) => state.labs.wellnessPackagesList);
  const loading = useSelector((state) => state.labs.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWellnessPackages());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.centerPackages);
    setUpdatedList(data.centerPackages);
  }, [data.centerPackages]);

  const toggleNewWellnessPackageModal = () => {
    setNewWellnessPackageModalOpen((prev) => !prev);
  };

  const toggleUpdateWellnessPackageModal = () => {
    setUpdateWellnessPackageModalOpen((prev) => !prev);
  };

  const getPage = (index) => {
    dispatch(
      fetchWellnessPackages({
        page: index,
      })
    );
  };

  const createNewProduct = (details) => {
    dispatch(addWellnessPackage(details));
  };

  const updateWellnessPackage = (details) => {
    dispatch(editWellnessPackage(details));
  };

  const deleteRecord = async (record) => {
    await dispatch(removeWellnessPackage(record));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    setUpdatedList(
      filteredList.filter((product) => {
        const price = product?.price;
        const name = product?.name.toLowerCase();
        const testCenter = product?.testcenter?.center_name.toLowerCase();
        const description = product?.description.toLowerCase();
        const homeTest = (product?.home_test).toString();
        const discount = product?.discount;
        const actualPrice = product?.slashed_amount;

        const search = e.target.value.toLowerCase();
        return (
          `${price}`.includes(search) ||
          name?.includes(search) ||
          testCenter?.includes(search) ||
          description?.includes(search) ||
          homeTest?.includes(search) ||
          `${discount}`?.includes(search) ||
          `${actualPrice}`?.includes(search)
        );
      })
    );
  };

  return (
    <>
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col">
                  <h1 className="mb-0" style={{ color: "#ea526f" }}>
                    {updatedList.length}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      WELLNESS PACKAGES
                    </span>
                  </h1>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <div className="col">
                  <Button
                    color="default"
                    type="button"
                    className="float-right"
                    onClick={() => setNewWellnessPackageModalOpen(true)}
                  >
                    + ADD WELLNESS PACKAGE
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewWellnessPackageModal()}
                    isOpen={newWellnessPackageModalOpen}
                  >
                    <NewWellnessPackageModal
                      handleSubmit={createNewProduct}
                      toggleNewWellnessPackageModal={
                        toggleNewWellnessPackageModal
                      }
                    />
                  </Modal>
                  <Modal
                    fade={false}
                    toggle={() => toggleUpdateWellnessPackageModal()}
                    isOpen={updateWellnessPackageModalOpen}
                  >
                    <UpdateWellnessPackageModal
                      handleSubmit={updateWellnessPackage}
                      activeWellnessPackage={activeWellnessPackage}
                      toggleUpdateWellnessPackageModal={
                        toggleUpdateWellnessPackageModal
                      }
                    />
                  </Modal>
                </div>
                {/* <div className="col">Add</div> */}
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    TEST CENTER
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DESCRIPTION
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    HOME TEST
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PRICE
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DISCOUNT
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ACTUAL PRICE
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((product) => {
                    return (
                      <tr key={product._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="test img"
                                src={
                                  product?.testcenter
                                    ? product.testcenter.profile_image
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0"
                                data-test="test_product-name"
                                style={{
                                  fontSize: "0.85rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                <strong>{product.name}</strong>
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{product?.testcenter?.center_name}</strong>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{product?.description}</strong>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>
                              {product?.home_test ? "True" : "False"}
                            </strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product?.price}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product?.discount}</strong>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product?.slashed_amount}</strong>
                          </div>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => {
                                  setUpdateWellnessPackageModalOpen(true);
                                  setActiveWellnessPackage(product);
                                }}
                              >
                                Update
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  deleteRecord({
                                    id: product._id,
                                    test_center_id: product.testcenter._id,
                                  })
                                }
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default WellnessPackagesList;
