import React from "react";
// import { Button } from "reactstrap";

import PatientsList from "components/Patients/PatientsList";

const Patients = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD PATIENT
      </Button> */}
      <PatientsList></PatientsList>
    </>
  );
};

export default Patients;
