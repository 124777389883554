import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchReports } from "../../services/api/reports";

export const fetchReportsList = createAsyncThunk(
  "admins/fetchReportsList",
  async (params) => {
    const response = await fetchReports(params);
    return response;
  }
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    reportsList: {
      reports: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReportsList.fulfilled, (state, action) => {
      state.reportsList = action.payload;
    });
  },
});

export default reportsSlice.reducer;
