import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
  createContext,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { login, logout } from "store/slices/auth";

const AuthContext = createContext({});

const getTokens = () => {
  let loggedIn = false;
  const accessToken = localStorage.getItem("access_token");
  // const refreshToken = localStorage.getItem("refresh_token");

  if (
    accessToken
    // && refreshToken
  )
    loggedIn = true;

  return loggedIn;
};

export const AuthProviderContext = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const tokensAreSet = getTokens();

  useEffect(() => {
    const authOnlyPaths = [
      "/auth/login",
      // "/auth/forgot-password",
      // "/auth/reset-password",
    ];

    if (tokensAreSet && authOnlyPaths.includes(location.pathname)) {
      history.push("/auth/login");
    } else if (!tokensAreSet && !authOnlyPaths.includes(location.pathname)) {
      history.push("/auth/login");
    }
  }, [location.pathname, tokensAreSet]);

  const loginAdmin = async (details) => {
    setLoading(true);
    await dispatch(login(details));
    setIsLoggedIn(true);
    setLoading(false);
    history.push("/admin/index");
  };

  const logoutAdmin = useCallback(async () => {
    // dispatch logoutAdmin
    setLoading(true);
    await dispatch(logout());
    setIsLoggedIn(false);
    setLoading(false);
    history.push("/auth/login");
  }, [history]);

  const memoedValue = useMemo(
    () => ({
      loginAdmin,
      logoutAdmin,
      isLoggedIn,
    }),
    [loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
