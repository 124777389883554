import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchInvoices, createInvoice } from "../../services/api/payments";

export const fetchInvoicesList = createAsyncThunk(
  "invoices/fetchList",
  async (params) => {
    console.log("bug", params);
    const response = await fetchInvoices(params);
    console.log("hello world", response);
    return response;
  }
);

export const createNewInvoice = createAsyncThunk(
  "invoices/createInvoice",
  async (params) => {
    const response = await createInvoice(params);

    return response;
  }
);

export const countriesSlice = createSlice({
  name: "invoices",
  initialState: {
    loading: false,
    invoicesList: {
      invoices: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvoicesList.fulfilled, (state, action) => {
      state.invoicesList.invoices = action.payload.invoices;
      state.invoicesList.metadata = action.payload.metadata || {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      };
    });

    builder.addCase(createNewInvoice.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createNewInvoice.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default countriesSlice.reducer;
