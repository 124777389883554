import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchCities,
  editCity,
  deleteCity,
  createCity,
} from "../../services/api/cities";

export const fetchCitiesList = createAsyncThunk(
  "cities/fetchList",
  async (params) => {
    const response = await fetchCities(params);
    return response;
  }
);

export const updateCityStatus = createAsyncThunk(
  "cities/updateStatus",
  async (params) => {
    const response = await editCity(params);

    return response;
  }
);

export const createNewCity = createAsyncThunk(
  "cities/createCity",
  async (params) => {
    const response = await createCity(params);
    return response;
  }
);

export const deleteCityAccount = createAsyncThunk(
  "cities/deleteCityAccount",
  async (params) => {
    const response = await deleteCity(params);
    return response;
  }
);

export const citiesSlice = createSlice({
  name: "cities",
  initialState: {
    loading: false,
    citiesList: {
      cities: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCitiesList.fulfilled, (state, action) => {
      state.citiesList = action.payload;
    });
    builder.addCase(updateCityStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateCityStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCityAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCityAccount.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export default citiesSlice.reducer;
