import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchAppointments } from "../../services/api/appointments";

export const fetchAppointmentsList = createAsyncThunk(
  "appointments/fetchAppointments",
  async (params) => {
    const response = await fetchAppointments(params);
    return response;
  }
);
export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: {
    loading: false,
    appointmentsList: {
      appointments: [],
      metadata: {
        current_page: 1,
        has_next_page: false,
        has_previous_page: false,
        last_page: 1,
        next_page: 1,
        previous_page: 0,
        total: 0,
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppointmentsList.fulfilled, (state, action) => {
      state.appointmentsList = action.payload;
    });
  },
});

export default appointmentsSlice.reducer;
