import client from "../axios.js";
import { toast } from "react-toastify";

/* PHARMACY PROFILES */

export async function createPharmacy(pharm) {
  try {
    const response = await client.post("/auth/create_pharmacy", pharm, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PHARMACY CREATION ERR]:", error);
    throw new Error("An error occured while creating a pharmacy");
  }
}

export async function fetchPharmacies(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/pharmacy/pharmacy_list?getAll=true&page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[PHARMACY FETCH ERR]:", error);
    throw new Error("An error occured while fetching pharmacies");
  }
}

export async function getPharmacyProfile(id) {
  try {
    const response = await client.get(`/pharmacy/get_pharmacy${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("[PHARMACY PROF ERR]:", error);
    throw new Error("An error occured while finding a pharmacies profile");
  }
}

export async function editPharmaciesProfile(data) {
  try {
    const { pharmId, ...updates } = data;

    await client.put(`/pharmacy/pharmacy_edit/${pharmId}`, updates, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("[PHARMACY EDIT ERR]:", error);
    throw new Error("An error occured while editing a pharmacy");
  }
}

export async function deletePharmacy(id) {
  try {
    // https://staging.zurihealthadmin.com/api/v2/pharmacy/pharmacy_product_delete/:id
    const response = await client.delete(
      `/pharmacy/pharmacy_product_delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = response.data;
    console.log(data);
    toast("Product Successfully Deleted");
  } catch (error) {
    console.log("[DELETE FAILED]:", error);
    toast("Deletion Fail");
    throw new Error("An error occured while deleting a pharmacy");
  }
}

/* PHARMACY ORDERS */
export async function fetchPharmacyOrders(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/pharmacy/pharmacy_orders?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[PHARMACY FETCH ERR]:", error);
    throw new Error("An error occured while fetching pharmacies");
  }
}

/* PHARMACY WEB ORDERS */
export async function fetchPharmacyOrdersWeb(
  params = {
    page: 1,
    email: "benardogutu65@gmail.com",
    filters: {},
  }
) {
  try {
    const response = await client.get(
      `/pharmacy/web_pharmacy_orders/${params.email}/${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("[PHARMACY FETCH ERR]:", error);
    throw new Error("An error occured while fetching pharmacies");
  }
}

export async function updatePharmacyOrderStatus({ orderId, status }) {
  try {
    const response = await client.post(
      `/pharmacy/pharmacy_order_status/${orderId}`,
      { order_status: status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    console.log(data);
  } catch (error) {
    console.log("ORDER STATUS UPDATE ERR]:", error);
    throw new Error("An error occured while updating order status");
  }
}

/* PHARMACY PRODUCTS */
export async function createPharmacyProduct(product) {
  try {
    const response = await client.post("/pharmacy/pharmacy_product", product, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    toast("Product created successfully");

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PRODUCT CREATION ERR]:", error);
    toast("Product creation error");
    throw new Error("An error occured while creating a product");
  }
}

// const token = localStorage.getItem('access_token')

export async function createCSVPharmarcyProduct(file) {
  console.log("file", file);
  try {
    const { pharmacy_product_csv } = file;
    const formData = new FormData();
    formData.append("pharmacy_product_csv", pharmacy_product_csv);

    const response = await client.post(
      "/pharmacy/pharmacy_product_upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toast("CSV Product created successfully");

    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PRODUCT CREATION ERR]:", error);
    toast("Product creation error");
    throw new Error("An error occured while creating a product");
  }
}

export async function fetchPharmacyProductsList(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const { page } = params;
    const response = await client.get(
      `/pharmacy/pharmacy_product_list?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("PRODUCTS FETCH ERR]:", error);
    throw new Error("An error occured while fetching products");
  }
}

export async function fetchPharmacyProductCategories(
  params = {
    page: 1,
    filters: {},
  }
) {
  try {
    const { page } = params;
    const response = await client.get(
      `/pharmacy_category/category_list?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("CATEGORIES FETCH ERR]:", error);
    throw new Error("An error occured while fetching product categories");
  }
}

export async function createPharmacyProductCategory(category) {
  try {
    const response = await client.post(
      "/pharmacy_category/category",
      category,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PHARMACY CATEGORY CREATION ERR]:", error);
    throw new Error("An error occured while creating a pharmacy category");
  }
}
export async function updatePharmacyProductCategory(update) {
  try {
    const response = await client.put(
      `/pharmacy_category/category_profile_edit/${update.id}`,
      update,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PHARMACY CATEGORY UPDATE ERR]:", error);
    throw new Error("An error occured while UPDATING a pharmacy category");
  }
}
export async function deletePharmacyProductCategory(id) {
  try {
    const response = await client.delete(
      `/pharmacy_category/category_delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = response.data;
    console.log(data);
  } catch (error) {
    console.log("PHARMACY CATEGORY DELETE ERR]:", error);
    throw new Error("An error occured while deleting a pharmacy category");
  }
}
