import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  // DropdownMenu,
  // DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Media,
  Table,
  Container,
  Row,
  Button,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

// import Pagination from "components/Pagination/Pagination";
import NewAdminForm from "components/Admin/modals/NewAdminForm";

import {
  fetchAdminsList,
  createAdminAccount,
  // updateAdminProfile,
  // deleteAdminAccount,
} from "store/slices/admins";
import { formatDate } from "utils/date";

const AdminsList = (props) => {
  const [newAdminModalOpen, setNewAdminModalOpen] = useState(false);
  const data = useSelector((state) => state.admins.adminsList);
  const loading = useSelector((state) => state.admins.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdminsList());
  }, [dispatch, loading]);

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const toggleNewAdminModal = () => {
    setNewAdminModalOpen(!newAdminModalOpen);
  };

  // const getPage = (index) => {
  //   dispatch(
  //     fetchAdminsList({
  //       page: index,
  //     })
  //   );
  // };

  const createNewAdmin = (details) => {
    try {
      dispatch(createAdminAccount(details));
      toggleNewAdminModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h1 className="mb-0" style={{ color: "#ea526f" }}>
                      {data.accounts.length}{" "}
                      <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                        ADMINS
                      </span>
                    </h1>
                  </div>
                  <div className="col">
                    <Button
                      color="default"
                      type="button"
                      className="float-right"
                      onClick={() => setNewAdminModalOpen(true)}
                    >
                      + ADD ADMIN
                    </Button>
                    <Modal
                      fade={false}
                      toggle={() => toggleNewAdminModal()}
                      isOpen={newAdminModalOpen}
                    >
                      <NewAdminForm
                        handleSubmit={createNewAdmin}
                        toggleNewAdminModal={toggleNewAdminModal}
                      />
                    </Modal>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      NAME
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CONTACT
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      SCOPE
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      ACCOUNT STATUS
                    </th>
                    <th scope="col" style={{ fontSize: "0.75rem" }}>
                      CREATED ON
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.accounts.map((admin) => {
                    return (
                      <tr key={admin._id}>
                        <th scope="row">
                          <span
                            className="mb-0 text-sm"
                            style={{ textTransform: "capitalize" }}
                          >
                            {admin.first_name} {admin.last_name}
                          </span>
                        </th>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{admin.email}</strong>
                          </div>
                          <div
                            style={{ color: "#8898aa", fontSize: "0.75rem" }}
                          >
                            {admin.country_code} {admin.mobile_no}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{admin.scope}</strong>
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(admin.account_status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {admin.account_status}
                          </Badge>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {formatDate(admin.createdAt)}
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            {/* <DropdownMenu className="dropdown-menu-arrow" right>
                              {admin.account_status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(admin._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {admin.account_status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(admin._id, "deactivated")
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() => deleteDoctorProfile(admin._id)}
                              >
                                Delete
                              </DropdownItem> */}

                            {/* <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem> */}
                            {/* </DropdownMenu> */}
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {/* <Pagination
                  metadata={data.metadata}
                  getPage={getPage}
                ></Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AdminsList;
