import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Row,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

import NewPharmCategoryForm from "components/Pharmacies/modals/NewPharmCategory";

// import Pagination from "../Pagination/Pagination";
import {
  fetchCategoryList,
  createNewPharmacyCategory,
  deleteCategory,
} from "store/slices/pharmacyProductCategories";
import SearchFilter from "components/SearchFilter/SearchFilter";
import UpdatePharmCategoryForm from "./modals/UpdateCategoryForm";
import { updatePharmacyCategory } from "store/slices/pharmacyProductCategories";
// import { formatDate } from "utils/date";

const PharmProductCategoryList = (props) => {
  const [newPharmCategoryModalOpen, setNewPharmCategoryModalOpen] =
    useState(false);
  const data = useSelector(
    (state) => state.pharmacyProductCategories.categoriesList
  );
  const loading = useSelector(
    (state) => state.pharmacyProductCategories.loading
  );

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [updateCategoryModalOpen, setUpdateCategoryModalOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoryList());
  }, [dispatch, loading]);

  useEffect(() => {
    console.log(data.pharmacy_categories);
    setFilteredList(data.pharmacy_categories);
    setUpdatedList(data.pharmacy_categories);
  }, [data]);

  const getBadgeColor = (status = "Active") => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    // const upd =
    setUpdatedList(
      filteredList.filter((pharm_category) => {
        const name = pharm_category.name.toLowerCase();
        const description = pharm_category.description.toLowerCase();

        const search = e.target.value.toLowerCase();
        return name.includes(search) || description.includes(search);
      })
    );
  };

  /* const getPage = (index) => {
    dispatch(
      fetchCategoryList({
        page: index,
      })
    );
  }; */

  const updateCategoryStatus = (categoryId, status) => {
    /* dispatch(
      updateStatus({
        categoryId,
        status: status,
      })
    ); */
  };

  const toggleOpenUpdateCategoryModal = (category) => {
    setActiveCategory(category);
    setUpdateCategoryModalOpen(!updateCategoryModalOpen);
  };

  const handleDeleteCategory = (categoryId) => {
    dispatch(deleteCategory(categoryId));
  };
  const toggleNewPharmCategoryModal = () => {
    setNewPharmCategoryModalOpen(!newPharmCategoryModalOpen);
  };
  const createPharmacyCategory = (details) => {
    dispatch(createNewPharmacyCategory(details));
    toggleNewPharmCategoryModal();
  };

  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
                <div className="col">
                  <h1 className="mb-0" style={{ color: "#ea526f" }}>
                    {data.pharmacy_categories.length}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      PRODUCT CATEGORIES
                    </span>
                  </h1>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <div className="col">
                  <Button
                    color="default"
                    type="button"
                    className="float-right"
                    onClick={() => setNewPharmCategoryModalOpen(true)}
                  >
                    + ADD CATEGORY
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewPharmCategoryModal()}
                    isOpen={newPharmCategoryModalOpen}
                  >
                    <NewPharmCategoryForm
                      handleSubmit={createPharmacyCategory}
                      toggleNewPharmCategoryModal={toggleNewPharmCategoryModal}
                    />
                  </Modal>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    ID
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    DESCRIPTION
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    STATUS
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((category) => {
                    return (
                      <tr key={category._id}>
                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            {category._id}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.90rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{category.name}</strong>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: "0.90rem" }}>
                            {category.description}
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getBadgeColor(category.status)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {category.status || "Active"}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {category.status !== "active" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateCategoryStatus(category._id, "active")
                                  }
                                >
                                  Activate
                                </DropdownItem>
                              )}
                              {category.status !== "deactivated" && (
                                <DropdownItem
                                  onClick={() =>
                                    updateCategoryStatus(
                                      category._id,
                                      "deactivated"
                                    )
                                  }
                                >
                                  Deactivate
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={() =>
                                  toggleOpenUpdateCategoryModal(category)
                                }
                              >
                                Update
                              </DropdownItem>

                              <DropdownItem
                                onClick={() =>
                                  handleDeleteCategory(category._id)
                                }
                              >
                                Delete
                              </DropdownItem>

                              {/* <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <Modal
                fade={false}
                toggle={() => toggleOpenUpdateCategoryModal()}
                isOpen={updateCategoryModalOpen}
              >
                <UpdatePharmCategoryForm
                  handleSubmit={updatePharmacyCategory}
                  toggleNewPharmCategoryModal={toggleOpenUpdateCategoryModal}
                  activeCategory={activeCategory}
                />
              </Modal>
            </Table>
            <CardFooter className="py-4">
              {/* <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination> */}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default PharmProductCategoryList;
