import React from "react";
// import { Button } from "reactstrap";

import AdvertsList from "components/Advertisements/AdvertsList";

const Adverts = () => {
  return (
    <>
      {/* <Button color="default" type="button" className="float-right mt-7">
        + ADD ADVERT
      </Button> */}
      <AdvertsList></AdvertsList>
    </>
  );
};

export default Adverts;
