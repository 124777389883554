import React, { useState } from "react";
import { Container } from "reactstrap";

import PharmacyList from "components/Pharmacies/PharmacyList";
import PharmacyOrdersList from "components/Pharmacies/PharmacyOrdersList";
import PharmacyProductsList from "components/Pharmacies/PharmacyProductsList";
import PharmacyProductCategoriesList from "components/Pharmacies/ProductCategoriesList";
import PharmacyProductSubCategoriesList from "components/Pharmacies/ProductSubcategoriesList";
import PharmacyOrdersWebList from "components/Pharmacies/PharmacyOrdersWebList";

const tabs = [
  "Orders from Apps",
  "Orders from Web",
  "Stores",
  "Product Categories",
  "Product SubCategories",
  "Products",
  // "Prescriptions"
];

const Pharmacies = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const renderContent = () => {
    switch (activeTab) {
      case "Stores":
        return <PharmacyList></PharmacyList>;
      case "Orders from Apps":
        return <PharmacyOrdersList></PharmacyOrdersList>;
      case "Orders from Web":
        return <PharmacyOrdersWebList></PharmacyOrdersWebList>;
      case "Products":
        return <PharmacyProductsList></PharmacyProductsList>;
      case "Product Categories":
        return <PharmacyProductCategoriesList></PharmacyProductCategoriesList>;
      case "Product SubCategories":
        return (
          <PharmacyProductSubCategoriesList></PharmacyProductSubCategoriesList>
        );
      default:
        break;
    }
  };

  return (
    <Container fluid>
      <nav className="nav nav-pills nav-justified pt-7">
        {tabs.map((tab) => {
          return (
            <a
              key={tab}
              className={`nav-link mr-2 ${activeTab === tab ? "active" : null}`}
              href={`#${tab}`}
              onClick={() => {
                setActiveTab(tab);
              }}
            >
              <strong>{tab}</strong>
            </a>
          );
        })}
      </nav>
      <>{renderContent()}</>
    </Container>
  );
};

export default Pharmacies;
