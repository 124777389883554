import React from "react";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const ListPagination = ({ metadata, getPage }) => {
  const pageNumbers = () => {
    const elements = Array.from({ length: metadata.last_page }, (v, i) => {
      return (
        <PaginationItem
          key={i}
          className={metadata.current_page === i + 1 ? "active" : null}
        >
          <PaginationLink
            onClick={() => getPage(i + 1)}
            disabled={metadata.current_page === i + 1}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    });

    let start = metadata.current_page - 5;
    let end = metadata.current_page + 5;

    if (start < 0) {
      start = 0;
      end = 10;
    }

    if (end > elements.length) {
      end = elements.length;
      start = end - 10;
    }

    return elements.slice(start, end);
  };

  return (
    <nav aria-label="...">
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem>
          <PaginationLink
            onClick={() => getPage(1)}
            tabIndex="-1"
            disabled={
              !(metadata.current_page !== 1 && metadata.has_previous_page)
            }
          >
            <i className="fas fa-angle-double-left" />
            <span className="sr-only">First</span>
          </PaginationLink>
        </PaginationItem>

        <PaginationItem>
          <PaginationLink
            onClick={() => getPage(metadata.previous_page)}
            tabIndex="-1"
            disabled={!metadata.has_previous_page}
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {pageNumbers()}

        <PaginationItem>
          <PaginationLink
            onClick={() => getPage(metadata.next_page)}
            disabled={!metadata.has_next_page}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>

        <PaginationItem>
          <PaginationLink
            onClick={() => getPage(metadata.last_page)}
            tabIndex="-1"
            disabled={
              !(
                metadata.current_page !== metadata.last_page &&
                metadata.has_next_page
              )
            }
          >
            <i className="fas fa-angle-double-right" />
            <span className="sr-only">Last</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  );
};

export default ListPagination;
