import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row,
  Container,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Col,
  Modal,
  // UncontrolledTooltip,
} from "reactstrap";

import Pagination from "components/Pagination/Pagination";
import NewProductForm from "components/Labs/Modals/NewProductForm";

import {
  fetchLabsProductsList,
  // createProduct
} from "store/slices/labs";
import SearchFilter from "components/SearchFilter/SearchFilter";
import UpdateTestProductModal from "./Modals/UpdateTestProductModal";
import { updateTestProduct } from "store/slices/labs";
import { deleteProduct } from "store/slices/labs";

const LabsProductsList = (props) => {
  const [newProductModalOpen, setNewProductModalOpen] = useState(false);
  const data = useSelector((state) => state.labs.labsProductsList);
  const loading = useSelector((state) => state.labs.loading);

  const [filteredList, setFilteredList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  const [updateTestProductModalOpen, setUpdateTestProductModalOpen] =
    useState(false);
  const [activeTestProduct, setActiveTestProduct] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLabsProductsList());
  }, [dispatch, loading]);

  useEffect(() => {
    setFilteredList(data.test_products);
    setUpdatedList(data.test_products);
  }, [data.test_products]);

  const toggleUpdateTestProductModal = () => {
    setUpdateTestProductModalOpen((prev) => !prev);
  };

  const editTestProduct = (details) => {
    dispatch(updateTestProduct(details));
  };

  const deleteTestProduct = (id) => {
    dispatch(deleteProduct(id));
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "pending":
        return "badge-warning";
      case "deactivated":
        return "badge-dark";
      default:
        break;
    }
  };

  const toggleNewProductModal = () => {
    setNewProductModalOpen(!newProductModalOpen);
  };

  const getHomeTestBadge = (bool) => (bool ? "badge-success" : "badge-dark");

  const getPage = (index) => {
    dispatch(
      fetchLabsProductsList({
        page: index,
      })
    );
  };

  const createNewProduct = (details) => {
    // dispatch(createProduct(details));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    setUpdatedList(
      filteredList.filter((product) => {
        const price = product.price.toString();
        const name = product.name.toLowerCase();
        const centerName = product.test_center.center_name.toLowerCase();
        const category = product.category.name.toLowerCase();

        const search = e.target.value.toLowerCase();
        return (
          price.includes(search) ||
          name.includes(search) ||
          centerName.includes(search) ||
          category.includes(search)
        );
      })
    );
  };

  return (
    <>
      {/* Table */}
      <Row>
        <div className="col mt-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col">
                  <h1 className="mb-0" style={{ color: "#ea526f" }}>
                    {updatedList.length}{" "}
                    <span style={{ color: "#8898aa", fontSize: "0.75rem" }}>
                      PRODUCTS
                    </span>
                  </h1>
                </div>
                <SearchFilter
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleChange={handleChange}
                />
                <div className="col">
                  <Button
                    color="default"
                    type="button"
                    className="float-right"
                    onClick={() => setNewProductModalOpen(true)}
                  >
                    + ADD PRODUCT
                  </Button>
                  <Modal
                    fade={false}
                    toggle={() => toggleNewProductModal()}
                    isOpen={newProductModalOpen}
                  >
                    <NewProductForm
                      handleSubmit={createNewProduct}
                      toggleNewProductModal={toggleNewProductModal}
                    />
                  </Modal>
                  <Modal
                    fade={false}
                    toggle={() => toggleUpdateTestProductModal()}
                    isOpen={updateTestProductModalOpen}
                  >
                    <UpdateTestProductModal
                      handleSubmit={editTestProduct}
                      activeTestProduct={activeTestProduct}
                      toggleUpdateTestProductModal={
                        toggleUpdateTestProductModal
                      }
                    />
                  </Modal>
                </div>
                {/* <div className="col">Add</div> */}
              </Row>
              {/* <hr /> */}
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    NAME
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    LAB
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    CATEGORY
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    HOME-PICKUP?
                  </th>
                  <th scope="col" style={{ fontSize: "0.75rem" }}>
                    PRICE
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {updatedList &&
                  updatedList.map((product) => {
                    return (
                      <tr key={product._id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="test img"
                                src={
                                  product.imageUrl?.length
                                    ? product.imageUrl[0].imgPath
                                    : require("../../assets/img/theme/avatar.jpeg")
                                }
                                style={{ maxHeight: "100%" }}
                              />
                            </a>
                            <Media>
                              <span
                                className="mb-0"
                                data-test="test_product-name"
                                style={{
                                  fontSize: "0.85rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                <strong>{product.name}</strong>
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{product.test_center?.center_name}</strong>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          >
                            <strong>{product.category?.name}</strong>
                          </div>
                        </td>
                        <td className="text-left">
                          <Badge
                            className={getHomeTestBadge(product.home_test)}
                            pill
                            style={{
                              fontSize: "0.77rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {product.home_test.toString()}
                          </Badge>
                        </td>
                        <td>
                          <div style={{ fontSize: "0.85rem" }}>
                            <strong>{product.price}</strong>
                          </div>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {
                                <DropdownItem
                                  onClick={() => {
                                    setUpdateTestProductModalOpen(true);
                                    setActiveTestProduct(product);
                                  }}
                                >
                                  Update
                                </DropdownItem>
                              }
                              {
                                <DropdownItem
                                  onClick={() =>
                                    updateStatus(product._id, "deactivated")
                                  }
                                  disabled
                                >
                                  Deactivate
                                </DropdownItem>
                              }
                              <DropdownItem
                                onClick={() => deleteTestProduct(product._id)}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <Pagination
                metadata={data.metadata}
                getPage={getPage}
              ></Pagination>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default LabsProductsList;
