import client from "services/axios";

export async function fetchReports(
  params = {
    page: 1,
    completed: "completed",
  }
) {
  // `/subscription/successful_airtime_payment_record?page=${params.page}&status=${params.completed}`
  try {
    const response = await client.get(
      `/subscription/successful_airtime_payment_record?page=${params.page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const transactions = await response.data.result.transactions;
    if (transactions.length > 0) {
      console.log("response from reports", transactions);
      return transactions;
    }
  } catch (error) {
    console.log("REPORTS FETCH ERR:", error);
    throw new Error("An error occured while fetching reports");
  }
}
