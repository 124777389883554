import { Input } from "reactstrap";

const SearchFilter = (props) => {
  const { filteredSearch, setSearchTerm, searchTerm, search, handleChange } =
    props;

  return (
    <div>
      <Input
        type="text"
        value={searchTerm}
        style={{ width: "15rem" }}
        onChange={(e) => handleChange(e)}
        placeholder="Search..."
      />
    </div>
  );
};

export default SearchFilter;
